/* eslint-disable @typescript-eslint/naming-convention */
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import type { RelatedPersonApiModel, BusinessApiModel } from '@lama/business-service-client';
import type { Collateral, PaymentPeriod } from '@lama/contracts';
import type { LenderProfile, User } from '@lama/user-service-client';
import { stateNameToAbbreviation } from '@lama/contracts';
import {
  applicationCollateralSelector,
  applicationGuarantorsSelector,
  applicationPrimaryBorrowerIdSelector,
  applicationBorrowingRelatedBusinessesSelector,
  relatedPeopleByRelationSelector,
} from '@lama/selectors';
import { format } from 'date-fns';

type LaserProYesNoAnswer = '' | 'N' | 'Y' | undefined;

const loanTypeToProductFileMapping = {
  'CD Secured Loan': '38.tov',
  'CD Secured Line of Credit': '38.tov',
  'Home Equity Line of Credit': '72.tov',
  'Contractor Construction': '105.tov',
  'Commercial Construction': '105.tov',
  'Secured Line of Credit': '32.tov',
  'Unsecured Line of Credit': '32.tov',
  'Commercial Real Estate': '42.tov',
  'Comm RE Balloon': '42.tov',
  'Res RE (1-4 Family)': '42.tov',
  'Res RE Balloon (1-4 Family)': '42.tov',
  'Manufactured Home': '42.tov',
  'Residential Lot Loan': '42.tov',
  'Commercial Lot Loan': '42.tov',
  Unsecured: '40.tov',
  'Checking Reserve': '69.tov',
};

const loanTypeToLaserProLoanType: Record<string, string> = {
  'Auto Loan': 'Installment',
  'Commercial Vehicle': 'Installment',
  Equipment: 'Installment',
  'CD Secured Loan': 'Installment',
  'Commercial Real Estate': 'Installment',
  'Res RE (1-4 Family)': 'Installment',
  'Manufactured Home': 'Installment',
  'Residential Lot Loan': 'Installment',
  'Commercial Lot Loan': 'Installment',
  Unsecured: 'Installment',
  'CD Secured Line of Credit': 'Revolving Line Of Credit',
  'Home Equity Line of Credit': 'Revolving Line Of Credit',
  'Secured Line of Credit': 'Revolving Line Of Credit',
  'Unsecured Line of Credit': 'Revolving Line Of Credit',
  'Checking Reserve': 'Revolving Line Of Credit',
  'Comm RE Balloon': 'Balloon',
  'Res RE Balloon (1-4 Family)': 'Balloon',
  'Contractor Construction': 'Construction Permanent',
  'Commercial Construction': 'Construction Permanent',
};

const gcPropertyTypeToLaserProSubType: Record<string, string> = {
  Condos: '1-4 Family',
  Duplex: '1-4 Family',
  'Manufactured Home': '1-4 Family',
  'Single-Family Home': '1-4 Family',
  'Town Home': '1-4 Family',
  Twinhome: '1-4 Family',
  'Vacation Home': '1-4 Family',
  'Manufactured Home and Lot': 'Lot',
  'Raw Land/Lot': 'Lot',
  'Mobile Home': 'Mobile Home',
  'Multi-Family Home': 'Multi-Family',
  Office: 'Office',
  'Hospitality, Restaurant, Hotel': 'Other RE',
  'Medical Office': 'Other RE',
  Curch: 'Other RE',
  'Mobile Home Park': 'Other RE',
  Motel: 'Other RE',
  Restaurant: 'Other RE',
  Other: 'Other RE',
  Retail: 'Retail',
  'Retail Store': 'Retail',
  'Industrial Properties': 'Warehouse',
  'Shop Condo': 'Warehouse',
  Warehouse: 'Warehouse',
};

const getPrefixedEntityArrayValues = (entities: any[], prefixBase: string) =>
  entities.reduce((acc, entity, index) => {
    const prefix = `${prefixBase}${index + 1}`;
    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        acc[`${prefix}_${key}`] = entity[key];
      }
    }
    return acc;
  }, {});

const businessTypeToLaserProEntityType = (businessLegalEntityType: string) => {
  switch (businessLegalEntityType) {
    case 'Sole Proprietorship': {
      return 'Sole Proprietorship';
    }
    case 'Partnership': {
      return 'Partnership';
    }
    case 'S Corp': {
      return 'Corporation';
    }
    case 'C Corp': {
      return 'Corporation';
    }
    case 'LLC': {
      return 'Limited Liability Company';
    }
    case 'Non Profit': {
      return 'Corporation';
    }
    default: {
      return '';
    }
  }
};

const laserProOfficerName = (person: Pick<RelatedPersonApiModel, 'firstName' | 'lastName'>) => {
  if (!person.firstName && !person.lastName) {
    return '';
  }

  return `${person.lastName}, ${person.firstName}`;
};

const lsaerProPersonFirstMiddleName = (person: Pick<RelatedPersonApiModel, 'firstName' | 'middleName'>) =>
  `${person.firstName}${person.middleName ? ` ${person.middleName}` : ''}`;

const corporationSubType = (businessLegalEntityType: string) => {
  switch (businessLegalEntityType) {
    case 'S Corp': {
      return 'S Corporation';
    }
    case 'C Corp': {
      return 'C Corporation';
    }
    default: {
      return 'Unknown';
    }
  }
};

const getEntitiesValues = (application: ApplicationApiModel) => {
  const borrowingBusinesses = applicationBorrowingRelatedBusinessesSelector(application);

  const borrowingBusinessValues = borrowingBusinesses.map(({ business }) => {
    const EntityType = businessTypeToLaserProEntityType(business.legalEntityType ?? '');
    const EntitySubType = EntityType === 'Corporation' ? corporationSubType(business.legalEntityType ?? '') : '';
    const Capacity = 'Borrower';
    const LastName = business.legalName ?? '';
    const tin = business.tin ? `${business.tin.slice(0, 2)}-${business.tin.slice(2)}` : '';
    const AddrLine1 = business.addresses?.[0]?.address1 ?? '';
    const City = business.addresses?.[0]?.city ?? '';
    const ST = stateNameToAbbreviation(business.addresses?.[0]?.state ?? '') ?? '';
    const Zip = business.addresses?.[0]?.zip ?? '';
    const MailingAddr1 = business.addresses?.[0]?.address1 ?? '';
    const MailingCity = business.addresses?.[0]?.city ?? '';
    const MailingST = stateNameToAbbreviation(business.addresses?.[0]?.state ?? '') ?? '';
    const MailingZip = business.addresses?.[0]?.zip ?? '';
    const PrimaryPhone = business.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
    const SIC = business.naicsCodes?.[0]?.slice(0, 5) ?? '';
    const PartnershipType = business.partnershipType ?? '';
    const NonprofitYN: LaserProYesNoAnswer = business.legalEntityType === 'Non Profit' ? 'Y' : 'N';

    return {
      EntityType,
      EntitySubType,
      Capacity,
      LastName,
      'SSN/TIN': tin,
      AddrLine1,
      City,
      ST,
      Zip,
      MailingAddr1,
      MailingCity,
      MailingST,
      MailingZip,
      PrimaryPhone,
      SIC,
      PartnershipType,
      NonprofitYN,
    };
  });

  const borrowingPeople = relatedPeopleByRelationSelector(application, 'borrower');

  const borrowingPeopleValues = borrowingPeople.map(({ person }) => {
    const EntityType = 'Individual';
    const Capacity = 'Borrower';
    const LastName = person.lastName;
    const FirstName = lsaerProPersonFirstMiddleName(person);
    const ssn = person.ssn ? `${person.ssn.slice(0, 3)}-${person.ssn.slice(3, 5)}-${person.ssn.slice(5)}` : '';
    const AddrLine1 = person.address?.address1 ?? '';
    const City = person.address?.city ?? '';
    const ST = stateNameToAbbreviation(person.address?.state ?? '') ?? '';
    const Zip = person.address?.zip ?? '';
    const MailingAddr1 = person.address?.address1 ?? '';
    const MailingCity = person.address?.city ?? '';
    const MailingST = stateNameToAbbreviation(person.address?.state ?? '') ?? '';
    const MailingZip = person.address?.zip ?? '';
    const PrimaryPhone = person.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
    const BirthDt = person.dateOfBirth ? format(new Date(person.dateOfBirth), 'MM-dd-yyyy') : '';
    const OwnershipPercentage = person.ownershipPercentage?.toString() ?? '';

    return {
      EntityType,
      Capacity,
      LastName,
      FirstName,
      'SSN/TIN': ssn,
      AddrLine1,
      City,
      ST,
      Zip,
      MailingAddr1,
      MailingCity,
      MailingST,
      MailingZip,
      PrimaryPhone,
      BirthDt,
      OwnershipPercentage,
    };
  });

  const guarantorPeople = applicationGuarantorsSelector(application);

  const guarantorPeopleValues = guarantorPeople.map((person) => {
    const EntityType = 'Individual';
    const Capacity = 'Guarantor';
    const LastName = person.lastName;
    const FirstName = lsaerProPersonFirstMiddleName(person);
    const ssn = person.ssn ? `${person.ssn.slice(0, 3)}-${person.ssn.slice(3, 5)}-${person.ssn.slice(5)}` : '';
    const AddrLine1 = person.address?.address1 ?? '';
    const City = person.address?.city ?? '';
    const ST = stateNameToAbbreviation(person.address?.state ?? '') ?? '';
    const Zip = person.address?.zip ?? '';
    const MailingAddr1 = person.address?.address1 ?? '';
    const MailingCity = person.address?.city ?? '';
    const MailingST = stateNameToAbbreviation(person.address?.state ?? '') ?? '';
    const MailingZip = person.address?.zip ?? '';
    const PrimaryPhone = person.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
    const BirthDt = person.dateOfBirth ? format(new Date(person.dateOfBirth), 'MM-dd-yyyy') : '';
    const OwnershipPercentage = person.ownershipPercentage?.toString() ?? '';

    return {
      EntityType,
      Capacity,
      FirstName,
      LastName,
      'SSN/TIN': ssn,
      AddrLine1,
      City,
      ST,
      Zip,
      MailingAddr1,
      MailingCity,
      MailingST,
      MailingZip,
      PrimaryPhone,
      BirthDt,
      OwnershipPercentage,
    };
  });

  return getPrefixedEntityArrayValues([...borrowingBusinessValues, ...borrowingPeopleValues, ...guarantorPeopleValues], 'Entity');
};

const getPriorLienFields = (collateral: Collateral) => {
  if (!collateral.lienDetails) {
    return {};
  }
  const LienHolderEntityType = collateral.lienDetails.lienHolderEntityType ?? '';
  const LienHolderFirstName = collateral.lienDetails.lienHolderFirstName ?? '';
  const LienHolderLastName = collateral.lienDetails.lienHolderLastName ?? '';
  const LienPriority = collateral.lienDetails.lienPriority ?? '';
  const LienCurrentBal = collateral.currentLienBalance?.toString() ?? '';

  return { LienHolderEntityType, LienHolderFirstName, LienHolderLastName, LienPriority, LienCurrentBal };
};

const getTitledFields = (collateral: Collateral) => {
  if (!collateral.vehicleDetails || collateral.typeSpecification !== 'Titled') {
    return {};
  }

  const vehcileCollaterals = ['Commercial Vehicle', 'Personal Vehicle'];

  const CollType = 'Titled';
  const CollSubType = vehcileCollaterals.includes(collateral.type ?? '') ? 'Motor Vehicle' : 'Other Titled';
  const CollLawSt = stateNameToAbbreviation(collateral.lawState ?? '') ?? '';
  const CollStreetAddress = collateral.vehicleDetails.address ?? '';
  const CollCity = collateral.vehicleDetails.city ?? '';
  const CollZip = collateral.vehicleDetails.zip ?? '';
  const CollST = stateNameToAbbreviation(collateral.vehicleDetails.state ?? '') ?? '';
  const CollBodyStyle = collateral.vehicleDetails.bodyStyle ?? '';
  const CollMake = collateral.vehicleDetails.make ?? '';
  const CollManufacturer = collateral.vehicleDetails.manufacturer ?? '';
  const CollModel = collateral.vehicleDetails.model ?? '';
  const CollSerialNum = ['Motor Vehicle', 'Ship'].includes(CollSubType) ? collateral.vehicleDetails.vin : '';
  const CollYear = collateral.vehicleDetails.year?.toString() ?? '';
  const CollMktValue = collateral.totalValue?.toString() ?? '';
  const SimpleDesc = collateral.description?.slice(0, 50) ?? '';
  const VIN = collateral.vehicleDetails.vin ?? '';

  return {
    CollType,
    CollSubType,
    CollLawSt,
    CollStreetAddress,
    CollCity,
    CollZip,
    CollST,
    CollBodyStyle,
    CollMake,
    CollManufacturer,
    CollModel,
    CollSerialNum,
    CollYear,
    CollMktValue,
    SimpleDesc,
    VIN,
  };
};

const getPossessoryFields = (collateral: Collateral, primaryBorrower?: BusinessApiModel) => {
  if (!collateral.cdDetails) {
    return {};
  }

  const CollType = 'Possessory';
  const CollSubType = collateral.type === 'CD' ? "Savings/CD's" : 'Securities';
  const CollPossType = collateral.type;
  const CollLawSt = stateNameToAbbreviation(primaryBorrower?.addresses?.[0]?.state ?? '') ?? '';
  const CollFaceValue = collateral.totalValue?.toString() ?? '';
  const CollMktValue = collateral.totalValue?.toString() ?? '';
  const CollNoteTo3rdPtyYN: LaserProYesNoAnswer =
    collateral.type === 'Securities' ? (collateral.possessoryDetails?.includeControlAgreement ? 'Y' : 'N') : undefined;
  const CollPossNum = collateral.cdDetails?.cdNumber ?? '';
  const CollIssuerType = 'Lender';

  return {
    CollType,
    CollSubType,
    CollPossType,
    CollLawSt,
    CollFaceValue,
    CollMktValue,
    CollPossNum,
    CollIssuerType,
    CollNoteTo3rdPtyYN,
  };
};

const getUccFields = (collateral: Collateral, primaryBorrower?: BusinessApiModel) => {
  if (collateral.typeSpecification !== 'UCC') {
    return {};
  }

  const CollType = 'UCC';
  const CollSubType = 'Described';
  const CollLawSt = stateNameToAbbreviation(primaryBorrower?.addresses?.[0]?.state ?? '') ?? '';
  const CollMktValue = collateral.totalValue?.toString() ?? '';

  return {
    CollType,
    CollSubType,
    CollLawSt,
    CollMktValue,
  };
};

const getRealEstateFields = (collateral: Collateral) => {
  if (!collateral.realEstateDetails) {
    return {};
  }

  const CollType = 'Real Estate';
  const CollSubType = collateral.type === 'Contruction' ? 'Construction' : gcPropertyTypeToLaserProSubType[collateral.type ?? ''] ?? '';
  const CollLawSt = stateNameToAbbreviation(collateral.realEstateDetails?.state ?? '') ?? '';
  const CollCity = collateral.realEstateDetails?.city ?? '';
  const CollCounty = collateral.realEstateDetails?.county ?? '';
  const CollST = stateNameToAbbreviation(collateral.realEstateDetails?.state ?? '') ?? '';
  const CollStreetAddress = collateral.realEstateDetails?.address ?? '';
  const CollZip = collateral.realEstateDetails?.zip ?? '';
  const ExistingLiensYN: LaserProYesNoAnswer = collateral.ownershipStatus === 'Under Lien' ? 'Y' : 'N';
  const FirstLienYN: LaserProYesNoAnswer = collateral.ownershipStatus === 'Free and clear' ? 'Y' : 'N';
  const OwnerOccupiedYN: LaserProYesNoAnswer = ['1-4 Family', 'Multi-Family', 'Construction', 'Mobile Home'].includes(
    collateral.realEstateDetails?.subType ?? '',
  )
    ? collateral.realEstateDetails.ownerOccupied
      ? 'Y'
      : 'N'
    : '';

  return {
    CollType,
    CollSubType,
    CollLawSt,
    CollCity,
    CollCounty,
    CollST,
    CollStreetAddress,
    CollZip,
    ExistingLiensYN,
    FirstLienYN,
    OwnerOccupiedYN,
  };
};

const getCollateralValues = (application: ApplicationApiModel, primaryBorrower?: BusinessApiModel) => {
  const collateralValues =
    applicationCollateralSelector(application)
      ?.sort((a, b) => (b.totalValue ?? 0) - (a.totalValue ?? 0))
      .map((collateral) => {
        const priorLien = getPriorLienFields(collateral);
        const realEstateFields = getRealEstateFields(collateral);
        const possessoryFields = getPossessoryFields(collateral, primaryBorrower);
        const titledFields = getTitledFields(collateral);
        const uccFields = getUccFields(collateral, primaryBorrower);

        const CollInsReqYN: LaserProYesNoAnswer = collateral.hasInsurance ? 'Y' : 'N';
        const BusnContactName = collateral.insuranceDetails?.agent;
        const CollCapacityID = collateral.insuranceDetails?.agency;
        const CollInsPolicyNumber = collateral.insuranceDetails?.policyNumber;
        const CollInsPhone = collateral.insuranceDetails?.phoneNumber;
        const PurchaseMoneyYN: LaserProYesNoAnswer = collateral.method === 'Purchase price' ? 'Y' : 'N';

        return {
          ...uccFields,
          ...titledFields,
          ...possessoryFields,
          ...realEstateFields,
          ...priorLien,
          CollInsReqYN,
          BusnContactName,
          CollCapacityID,
          CollInsPolicyNumber,
          CollInsPhone,
          PurchaseMoneyYN,
        };
      }) ?? [];

  return getPrefixedEntityArrayValues(collateralValues, 'L');
};

const getLaserProProduct = (opportunity: OpportunityApiModel) => {
  const {
    application: { collateral, loanType },
  } = opportunity;

  const mappedLoanTypes = Object.keys(loanTypeToProductFileMapping);

  if (loanType && mappedLoanTypes.includes(loanType)) {
    return loanTypeToProductFileMapping[loanType as keyof typeof loanTypeToProductFileMapping];
  }

  const mostValuableCollateral = collateral?.sort((a, b) => (b.totalValue ?? 0) - (a.totalValue ?? 0))[0];

  if (!mostValuableCollateral) {
    return;
  }

  if (mostValuableCollateral.typeSpecification === 'UCC') {
    return '45.tov';
  }

  if (mostValuableCollateral.typeSpecification === 'Titled') {
    return '39.tov';
  }

  return '39.tov';
};

const getTotalNumberOfPayments = (termLengthMonths: number, paymentPeriod: PaymentPeriod) => {
  switch (paymentPeriod) {
    case 'Weekly': {
      return termLengthMonths * 4;
    }
    case 'Bi-Weekly': {
      return termLengthMonths * 2;
    }
    case 'Semi-Monthly': {
      return termLengthMonths * 2;
    }
    case 'Monthly': {
      return termLengthMonths;
    }
    case 'Bi-Monthly': {
      return Math.ceil(termLengthMonths / 2);
    }
    case 'Quarterly': {
      return Math.ceil(termLengthMonths / 3);
    }
    case 'Semi-Annual': {
      return Math.ceil(termLengthMonths / 6);
    }
    case 'Annual': {
      return Math.ceil(termLengthMonths / 12);
    }
  }
};

const getGeneralDataValues = ({
  opportunity,
  assignedLenderProfiles,
  assignedUsers,
  laserProOfficerCodePrefix = '',
  primaryBorrower,
}: {
  opportunity: OpportunityApiModel;
  assignedUsers: User[];
  assignedLenderProfiles: LenderProfile[];
  laserProOfficerCodePrefix?: string;
  primaryBorrower?: BusinessApiModel;
}) => {
  const { application, terms, partnerSequenceNumber, branchCode } = opportunity;

  const transOfficer = assignedUsers?.find((user) => user.roles.includes('transOfficer'));
  const processor = assignedUsers?.find((user) => user.roles.includes('processor'));
  const processorOffierCode = assignedLenderProfiles?.find((lenderProfile) => lenderProfile.userId === processor?.id)?.officerCode ?? '';
  const transOfficerOffierCode =
    assignedLenderProfiles?.find((lenderProfile) => lenderProfile.userId === transOfficer?.id)?.officerCode ?? '';

  const AmtReq = application.requestedAmount?.toString() ?? '';
  const BorrST = stateNameToAbbreviation(primaryBorrower?.addresses?.[0]?.state ?? '') ?? '';
  const AppDt = application.submittedAt ? format(new Date(application.submittedAt), 'MM-dd-yyyy') : '';
  const AppIDNumber = partnerSequenceNumber ? `${partnerSequenceNumber}BL`.padStart(6, '0') : '';
  const ClassBranch = branchCode?.slice(0, 8) ?? '';
  const FirstPmtDt = terms?.firstPaymentDate ? format(new Date(terms.firstPaymentDate), 'MM-dd-yyyy') : '';
  const DisbDt = terms?.disbursementDate ? format(new Date(terms.disbursementDate), 'MM-dd-yyyy') : '';
  const NoteRate = terms?.rate?.isFixed ? terms.rate.spread : undefined;
  const NumOfPmts =
    terms?.termLength && terms.paymentPeriod ? getTotalNumberOfPayments(terms.termLength, terms.paymentPeriod).toString() : '';
  const PmtPrd = terms?.paymentPeriod ?? '';
  const RateType = terms?.rate?.isFixed ? 'Fixed' : 'Variable';
  const Processor = processorOffierCode ? `${laserProOfficerCodePrefix}${processorOffierCode}` : '';
  const ProcessorName = processor ? laserProOfficerName(processor) : '';
  const TransOfficer = transOfficerOffierCode ? `${laserProOfficerCodePrefix}${transOfficerOffierCode}` : '';
  const TransOfficerName = transOfficer ? laserProOfficerName(transOfficer) : '';
  const StandardProduct = getLaserProProduct(opportunity);
  const LoanType =
    application.loanType && Object.keys(loanTypeToLaserProLoanType).includes(application.loanType)
      ? loanTypeToLaserProLoanType[application.loanType]
      : '';
  const AutoPayYN = 'N';

  return {
    AmtReq,
    BorrST,
    AppDt,
    AppIDNumber,
    ClassBranch,
    FirstPmtDt,
    DisbDt,
    NumOfPmts,
    NoteRate,
    PmtPrd,
    LoanType,
    Processor,
    ProcessorName,
    RateType,
    StandardProduct,
    TransOfficer,
    TransOfficerName,
    AutoPayYN,
  };
};

const fieldDelimiter = '|';

export const getLaserProValuesRecord = ({
  opportunity,
  assignedLenderProfiles,
  assignedUsers,
  laserProOfficerCodePrefix,
}: {
  opportunity: OpportunityApiModel;
  assignedLenderProfiles: LenderProfile[];
  assignedUsers: User[];
  laserProOfficerCodePrefix?: string;
}) => {
  const { application } = opportunity;

  const primaryBorrowerId = applicationPrimaryBorrowerIdSelector(application);

  const primaryBorrower = applicationBorrowingRelatedBusinessesSelector(application).find(
    (borrower) => borrower.business.id === primaryBorrowerId,
  );

  const record: Record<string, number | string | undefined> = {
    ...getGeneralDataValues({
      opportunity,
      assignedUsers,
      assignedLenderProfiles,
      laserProOfficerCodePrefix,
      primaryBorrower: primaryBorrower?.business,
    }),
    ...getEntitiesValues(application),
    ...getCollateralValues(application, primaryBorrower?.business),
  };

  return record;
};

export const transformRecordToLaserProTextFormat = (record: Record<string, number | string | undefined>) =>
  Object.entries(record)
    .filter(([, value]) => value !== undefined && value !== '')
    .map(([key, value]) => `${key}${fieldDelimiter}${value}`)
    .join('\n');

export const transformToLaserProPremiumFormat = ({
  opportunity,
  assignedLenderProfiles,
  assignedUsers,
  laserProOfficerCodePrefix,
}: {
  opportunity: OpportunityApiModel;
  assignedLenderProfiles: LenderProfile[];
  assignedUsers: User[];
  laserProOfficerCodePrefix?: string;
}) => {
  const record: Record<string, number | string | undefined> = getLaserProValuesRecord({
    opportunity,
    assignedLenderProfiles,
    assignedUsers,
    laserProOfficerCodePrefix,
  });

  return transformRecordToLaserProTextFormat(record);
};
