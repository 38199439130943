import React, { useCallback, useContext, type FC } from 'react';
import * as yup from 'yup';
import { Button, Flex, Text } from '@lama/design-system';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Formik } from 'formik';
import { PropertyFormikInput } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import type { OpportunityApiModel } from '@lama/clients';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';

const validationSchema = yup.object({
  reason: yup.string().trim().required('Please provide a reason'),
});

interface ClearFraudulentActivityModalProps {
  opportunity: OpportunityApiModel;
  personId?: string;
  onClose: () => void;
}

export const ClearFraudulentActivityModal: FC<ClearFraudulentActivityModalProps> = ({ opportunity, onClose, personId }) => {
  const { mutateAsync: updateOpportunity } = useUpdateOpportunityMutation(opportunity.id);
  const { userId } = useContext(UserDetailsContext);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async ({ reason }: { reason: string }) => {
      if (!personId) {
        return;
      }

      await updateOpportunity({
        creditReportFraudulentActivityClearance: [
          ...(opportunity.creditReportFraudulentActivityClearance ?? []),
          {
            personId,
            clearedAt: new Date().toISOString(),
            clearedBy: userId!,
            reason,
          },
        ],
      });

      onClose();
    },
    [onClose, opportunity.creditReportFraudulentActivityClearance, personId, updateOpportunity, userId],
  );

  return (
    <Dialog open maxWidth={'md'} fullWidth>
      <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
        <CloseRounded fontSize={'large'} sx={{ color: grey[500] }} />
      </IconButton>
      <DialogContent sx={{ gap: 4, px: 10, py: 6 }}>
        <Formik onSubmit={onSubmit} initialValues={{ reason: '' }} validationSchema={validationSchema}>
          {({ handleSubmit }) => (
            <Flex flexDirection={'column'} gap={8} width={'100%'} justifyContent={'center'}>
              <Text variant={'h5'} textAlign={'center'}>
                {t('clearCreditReportFraudulentActivity.title')}
              </Text>
              <Flex flexDirection={'column'} gap={4}>
                <PropertyFormikInput name={'reason'} label={'Clearance Reason'} fullWidth required />
              </Flex>
              <Flex justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                <Button size={'l'} variant={'tertiary'} onClick={onClose as any}>
                  {t('clearCreditReportFraudulentActivity.cancel')}
                </Button>
                <Button size={'l'} variant={'primary'} onClick={handleSubmit}>
                  {t('clearCreditReportFraudulentActivity.cta')}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
