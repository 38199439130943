import { BaseDialog } from '@lama/app-components';
import type { FC } from 'react';
import React from 'react';
import { Flex, Text, Button } from '@lama/design-system';

interface OrderConfirmDialogProps {
  open: boolean;
  handleClose: () => void;
  confirm: () => void;
  title: string;
  message: string;
  note?: string;
  orderButtonLabel?: string;
}

export const OrderConfirmDialog: FC<OrderConfirmDialogProps> = ({ title, message, note, orderButtonLabel, open, handleClose, confirm }) => (
  <BaseDialog title={title} open={open} onClose={handleClose}>
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'body2'}>{message}</Text>
      <Text variant={'body4'} color={'secondary'}>
        {note}
      </Text>
      <Flex flexDirection={'row'} justifyContent={'flex-end'}>
        <Button variant={'tertiary'} color={'neutral'} onClick={handleClose}>
          {'Cancel'}
        </Button>
        <Button variant={'primary'} color={'primary'} onClick={confirm}>
          {orderButtonLabel ?? title}
        </Button>
      </Flex>
    </Flex>
  </BaseDialog>
);
