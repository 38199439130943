import React, { useCallback, useMemo, useState, type FC } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { businessName } from '@lama/selectors';
import { Tooltip } from '@mui/material';
import { FileXIcon } from '../../../../../../shared/icons/FileXIcon';
import { OrderConfirmDialog } from './OrderConfirmDialog.js';
import { LoadingDataBox } from './LoadingDataBox.js';
import { NoDataFoundBox } from './NoDataFoundBox.js';
import type { MiddeskBusinessClosingTaskProps } from './middeskBusinessClosingTaskProps';

export const MiddeskRegistrationBusinessTaskComponent: FC<MiddeskBusinessClosingTaskProps> = ({
  orderTracking,
  allowMiddeskPull,
  orderDocuments,
  onLoadingStatusChange,
  business,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const orderStatus = useMemo(() => orderTracking?.status, [orderTracking?.status]);

  const onPullClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const StateComponent = useMemo(() => {
    if (orderStatus === 'pending' || orderStatus === 'created') {
      return <LoadingDataBox loadingText={'Fetching registration documents, we’ll let you know once the results are back'} />;
    }

    if (orderStatus === 'completed') {
      return <NoDataFoundBox message={'No business registration documents found'} Icon={FileXIcon} />;
    }
  }, [orderStatus]);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onConfirmOrder = useCallback(async () => {
    await orderDocuments();
    onLoadingStatusChange(true);
    onCloseModal();
  }, [orderDocuments, onLoadingStatusChange, onCloseModal]);

  return (
    <>
      <Flex key={business.id} flexDirection={'column'} gap={2} width={'100%'}>
        <Flex flexDirection={'row'} justifyContent={'space-between'}>
          <Text variant={'body1'}>{businessName(business)}</Text>
          {!orderStatus ? (
            <Tooltip title={allowMiddeskPull ? 'Order Business Registration' : 'Business information is missing'} arrow>
              <Button variant={'secondary'} color={'neutral'} onClick={onPullClick} disabled={!allowMiddeskPull}>
                {'Order Business Registration'}
              </Button>
            </Tooltip>
          ) : null}
        </Flex>
        {StateComponent ?? null}
      </Flex>
      <OrderConfirmDialog
        title={'Order Business Registration Document'}
        message={'You are about to order a Business Registration document. A pass through fee will apply per document.'}
        orderButtonLabel={'Order Document'}
        open={isModalOpen}
        handleClose={onCloseModal}
        confirm={onConfirmOrder}
      />
    </>
  );
};
