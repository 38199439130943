import { useContext, useMemo } from 'react';
import { getRequirementsByCategory } from '@lama/app-components';
import type { DocumentDetails, DocumentGroup } from '../types';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { useOpportunityRequirementsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { useDocumentGroupsByTopic } from './useDocumentGroupsByTopic';

export const useDocumentDetailsByEntity = (documents: DocumentDetails[]) => {
  const {
    application,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  const { data: allRequirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunityId, {});
  const { groups: topicGroups, loading: loadingGroups } = useDocumentGroupsByTopic(documents, allRequirements ?? []);

  return useMemo(() => {
    if (!topicGroups.length) {
      return {
        loading: false,
        groups: [],
      };
    }

    return {
      loading: loadingGroups || loadingRequirements,
      groups: allRequirements
        ? getRequirementsByCategory(allRequirements, application).map<DocumentGroup>((p) => {
            const subGroups = topicGroups.filter((group) => group.requirements?.some((req) => p.requirements.some((r) => r.id === req.id)));

            const entitySubGroups = subGroups.map((group) => ({
              ...group,
              // keep only the document relating to this requirement category
              documents: group.documents?.filter((doc) => p.requirements.some((r) => r.id === doc.requirement?.id)),
            }));

            return {
              title: p.categoryLongName,
              icon: p.categoryIcon,
              subtitle: p.entityRelation,
              subGroups: entitySubGroups,
            };
          })
        : [],
    };
  }, [allRequirements, application, loadingGroups, loadingRequirements, topicGroups]);
};
