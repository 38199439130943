import React, { useCallback, useMemo, useState, type FC } from 'react';
import { Button, Flex, greyPalette, Text } from '@lama/design-system';
import { businessName } from '@lama/selectors';
import { Tooltip } from '@mui/material';
import { isNil } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import { CheckCircleIcon } from '../../../../../../shared/icons/CheckCircleIcon';
import { useMiddeskSearchUccFillingsMutation } from './hooks/useMiddeskSearchUccFillingsMutation.js';
import { OrderUccFillingsBox } from './OrderUccFillingsBox.js';
import { OrderConfirmDialog } from './OrderConfirmDialog.js';
import { LoadingDataBox } from './LoadingDataBox.js';
import { NoDataFoundBox } from './NoDataFoundBox.js';
import type { MiddeskBusinessClosingTaskProps } from './middeskBusinessClosingTaskProps';

export const MiddeskUCCBusinessTaskComponent: FC<MiddeskBusinessClosingTaskProps> = ({
  taskId,
  middeskDocumentsTracking,
  orderTracking,
  searchOrder,
  allowMiddeskPull,
  orderDocuments,
  onLoadingStatusChange,
  business,
}) => {
  const { mutateAsync: searchLiens } = useMiddeskSearchUccFillingsMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const liensFound = useMemo(() => business.verifications?.middesk?.liens?.length, [business.verifications?.middesk]);

  const orderStatus = useMemo(() => orderTracking?.status, [orderTracking?.status]);

  const onPullClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const StateComponent = useMemo(() => {
    if (((orderStatus === 'pending' || orderStatus === 'created') && searchOrder) || (orderStatus === 'completed' && isNil(liensFound))) {
      return <LoadingDataBox loadingText={'Searching for UCC filings, we’ll let you know once the results are back'} />;
    }
    if ((orderStatus === 'pending' || orderStatus === 'created') && !searchOrder) {
      return <LoadingDataBox loadingText={'Fetching UCC documents, we’ll let you know once the results are back'} />;
    }

    if (orderStatus === 'completed' && searchOrder && !liensFound) {
      return <NoDataFoundBox message={'No liens found'} Icon={CheckCircleIcon} />;
    }

    if (orderStatus === 'completed' && !searchOrder && !middeskDocumentsTracking?.length) {
      return <NoDataFoundBox message={'No UCC Filing Documents found'} Icon={CheckCircleIcon} />;
    }
    if (orderStatus === 'completed' && searchOrder && liensFound) {
      return <OrderUccFillingsBox liensFound={liensFound} onPullDocumentsClick={onPullClick} searchedAt={orderTracking?.lastSearchedAt} />;
    }
  }, [orderStatus, searchOrder, liensFound, middeskDocumentsTracking?.length, onPullClick, orderTracking?.lastSearchedAt]);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onSearchLiensClick = useCallback(async () => {
    await searchLiens({ businessId: business.id, taskId });
    onLoadingStatusChange(true);
  }, [business.id, onLoadingStatusChange, searchLiens, taskId]);

  const onConfirmOrder = useCallback(async () => {
    await orderDocuments();
    onLoadingStatusChange(true);
    onCloseModal();
  }, [orderDocuments, onLoadingStatusChange, onCloseModal]);

  return (
    <>
      <Flex key={business.id} flexDirection={'column'} gap={2} flex={1}>
        <Flex flexDirection={'row'} justifyContent={'space-between'}>
          <Text variant={'body1'}>{businessName(business)}</Text>
          {!orderStatus ? (
            <Tooltip title={allowMiddeskPull ? 'Search UCC Fillings' : 'Business information is missing'} arrow>
              <Button variant={'secondary'} color={'neutral'} onClick={onSearchLiensClick} disabled={!allowMiddeskPull}>
                {'Search UCC Fillings'}
              </Button>
            </Tooltip>
          ) : null}
          {orderStatus === 'completed' ? (
            searchOrder ? (
              <Text variant={'body2'} color={greyPalette[500]}>
                {`Searched at: ${formatValue(orderTracking?.lastSearchedAt, 'date')}`}
              </Text>
            ) : (
              <Flex flexDirection={'row'} gap={2} alignItems={'center'}>
                <CheckCircleIcon color={greyPalette[500]} />
                <Text variant={'body2'} color={greyPalette[500]}>
                  {`${liensFound} liens found on ${formatValue(orderTracking?.lastSearchedAt, 'date')}`}
                </Text>
              </Flex>
            )
          ) : null}
        </Flex>
        {StateComponent ?? null}
      </Flex>
      {liensFound ? (
        <OrderConfirmDialog
          title={'Order UCC Filings'}
          message={`You are about to order documents for ${liensFound} liens (status open or unknown). A pass through fee will apply per lien`}
          note={
            'Please note: If any additional liens are found at the time of the order, their documents will also be retrieved and charged accordingly.'
          }
          open={isModalOpen}
          handleClose={onCloseModal}
          confirm={onConfirmOrder}
        />
      ) : null}
    </>
  );
};
