import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { applicationBorrowingRelatedBusinessesSelector, relatedCompaniesSelector } from '@lama/selectors';
import { ApplicationContext } from '../../../ApplicationContext';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { RelatedCompaniesSummarySegment } from '../../../shared/RelatedCompany/RelatedCompaniesSummarySegment';
import { RelatedCompanySegment } from '../../../shared/RelatedCompany/RelatedCompanySegment';

export const HanoverAffiliationSection: FC = () => {
  const { application } = useContext(ApplicationContext);

  const relatedCompanies = useMemo(() => {
    const borrower = applicationBorrowingRelatedBusinessesSelector(application)[0]?.business;

    return borrower ? relatedCompaniesSelector(application, borrower).map(({ business }) => business) : [];
  }, [application]);

  if (!relatedCompanies.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <RelatedCompaniesSummarySegment />
      {relatedCompanies.map((company) => (
        <RelatedCompanySegment key={company.id} relatedCompany={company} />
      ))}
      <InlineNote title={'Affiliate Details'} noteName={'Affiliates Overview'} />
    </Flex>
  );
};
