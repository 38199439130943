import type { MemoSection } from '@lama/contracts';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex } from '@lama/design-system';
import styled from 'styled-components';
import { LoadingPage } from '@lama/app-components';
import { keyBy } from 'lodash-es';
import { CommentsSegment } from '../../../../../shared/components/Comments/CommentsSegment';
import { SectionDecisioning } from '../../../shared/SectionDecisioning';
import { SectionTitle } from '../../../shared/SectionTitle/SectionTitle';
import { GenericSection } from '../../../shared/GenericSection/GenericSection';
import type { MultipliedSection } from '../../../shared/sectionUtils';
import { useGetPartnerSectionsQuery } from '../../../../../shared/hooks/react-query/partnerSections/useGetPartnerSectionsQuery';
import { ApplicationContext } from '../../../ApplicationContext';

const MemoContainer = styled(Flex)`
  @media print {
    page-break-inside: auto !important;
    break-inside: auto !important;
  }
`;

interface CreditMemoContentProps {
  section?: MultipliedSection<MemoSection>;
}

export const CreditMemoSectionComponent: FC<{ section: MultipliedSection<MemoSection>; printMode?: boolean }> = ({
  section,
  printMode,
}) => {
  const {
    opportunity: { partnerId },
  } = useContext(ApplicationContext);
  const { data: partnerSections, isPending: loadingPartnerSections } = useGetPartnerSectionsQuery(partnerId);

  const sectionConfigurationByKey = useMemo(() => keyBy(partnerSections, (c) => c.key), [partnerSections]);

  const sectionComponent = useMemo(() => {
    if (!section?.key) {
      return null;
    }

    const partnerSection = sectionConfigurationByKey[section.key];

    if (!partnerSection?.segments.length) {
      return null;
    }

    return (
      <GenericSection segments={partnerSection.segments} sectionId={section.id} sectionEntity={section.entity} printMode={printMode} />
    );
  }, [printMode, section.entity, section.id, section.key, sectionConfigurationByKey]);

  if (loadingPartnerSections) {
    return <LoadingPage />;
  }

  return sectionComponent;
};

export const CreditMemoContent: FC<CreditMemoContentProps> = ({ section }) => {
  if (!section) {
    return null;
  }

  return (
    <MemoContainer flexDirection={'column'} px={14} py={10} flex={1} overflow={'auto'} alignItems={'center'}>
      <Flex flexDirection={'column'} width={'100%'} maxWidth={'960px'} gap={8}>
        <Flex flexDirection={'column'} gap={4}>
          <SectionTitle sectionId={section.id} sectionType={'creditMemoSection'} title={section.prettyName} />
          {section.hideUnderwritingActions ? null : <SectionDecisioning sectionId={section.id} sectionType={'creditMemoSection'} />}
        </Flex>
        <CreditMemoSectionComponent section={section} />
        <Flex flexDirection={'column'} pt={20}>
          <CommentsSegment relatedItemId={section.id} relatedItemType={'creditMemoSection'} />
        </Flex>
      </Flex>
    </MemoContainer>
  );
};
