import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Button, Flex } from '@lama/design-system';
import { DocumentBox } from '@lama/app-components';
import { useApplicationDocumentsQuery } from '../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { ApplicationContext } from '../../../ApplicationContext';
import { useDeleteDocumentQuery } from '../../../../../shared/components/DocumentBox/hooks/useDeleteDocumentQuery';
import { useSubmitApplicationMutation } from '../../../../../shared/hooks/react-query/application/useSubmitApplicationMutation';
import type { RequirementScreenProps } from './types';

const SIGNED_DOCUMENT_TOPIC = 'signedDocument';

export const LenderSubmitScreen: FC<RequirementScreenProps> = () => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  const { data: documents, isPending: isLoadingDocuments } = useApplicationDocumentsQuery({ applicationId });
  const { mutateAsync: deleteDocument } = useDeleteDocumentQuery();
  const { mutate: signApplication, isPending: isSigningApplication } = useSubmitApplicationMutation(applicationId, opportunityId);
  const signedDocument = useMemo(() => documents?.find((document) => document.topic === SIGNED_DOCUMENT_TOPIC), [documents]);

  const onDeleteDocument = useCallback(async () => {
    if (signedDocument) {
      await deleteDocument({ documentId: signedDocument?.id });
    }
  }, [deleteDocument, signedDocument]);

  const onSubmitClick = useCallback(async () => {
    signApplication();
  }, [signApplication]);

  return (
    <Flex flexDirection={'column'} gap={20}>
      {!isLoadingDocuments && (
        <DocumentBox description={'Signed Application Form'} document={signedDocument} onDocumentRemoved={onDeleteDocument} />
      )}
      <Flex width={'100%'} justifyContent={'flex-start'}>
        <Button variant={'primary'} size={'m'} onClick={onSubmitClick} loading={isSigningApplication}>
          {'Submit'}
        </Button>
      </Flex>
    </Flex>
  );
};
