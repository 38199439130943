/* eslint-disable @typescript-eslint/naming-convention */

import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Collapse, Flex } from '@lama/design-system';
import { compact, isNil } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Fuse from 'fuse.js';
import type { LabeledValue } from '@lama/contracts';
import { ApplicationContext } from '../Application/ApplicationContext';
import { useApplicationDocumentsQuery } from '../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { useOpportunityRequirementsQuery } from '../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { DocumentGatheringCard } from '../Application/OpportunityRequirements/DocumentGathering';
import { DocumentHubEmptyState } from './DocumentHubEmptyState';
import { useDocumentDetails } from './hooks/useDocumentDetails';
import { autoCompleteFilterOptions, DocumentHubFilters } from './DocumentHubFilters';
import type { DocumentDetails, DocumentGroup, GroupDocumentListProps, GroupingOptions } from './types';
import { EntityDocumentList } from './EntityDocumentList';
import { RecentDocumentList } from './RecentDocumentList';
import { TopicDocumentList } from './TopicDocumentList';
import { DocumentList } from './DocumentList';
import { DemoDocumentSummaryV2 } from './DocumentSummary/DemoDocumentSummaryV2';

const documentListByGroup: Record<GroupingOptions, FC<GroupDocumentListProps>> = {
  entity: EntityDocumentList,
  recent: RecentDocumentList,
  topic: TopicDocumentList,
};

export const DocumentHubPageV2: FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);
  const { showDocumentGatheringCard, showDocumentHubSpreadingSummary, spreadingBackOfficeMode } = useFlags();
  const [searchParams] = useSearchParams();

  const initialGroupedBy = useMemo(() => {
    const groupParam = searchParams.get('groupedBy');
    return groupParam === 'entity' || groupParam === 'recent' || groupParam === 'topic' ? groupParam : 'entity';
  }, [searchParams]);

  const initialFilterBy = useMemo(() => {
    const filterParam = searchParams.get('filter');
    return autoCompleteFilterOptions.find((opt) => opt.value === filterParam) ?? autoCompleteFilterOptions.at(0)!;
  }, [searchParams]);

  const [groupedBy, setGroupedBy] = useState<GroupingOptions>(initialGroupedBy);
  const [filterBy, setFilterBy] = useState<LabeledValue>(initialFilterBy);
  const [searchQuery, setSearchQuery] = useState<string>(searchParams.get('search') || '');

  const { data: allRequirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunity.id, {});
  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId: application.id,
    includeDeleted: true,
  });

  const allDocumentDetails = useDocumentDetails(applicationDocuments ?? [], allRequirements ?? []);

  const nonDeletedDocuments = useMemo(() => allDocumentDetails.filter((doc) => doc?.document?.status !== 'Deleted'), [allDocumentDetails]);

  const filteredDocuments = useMemo((): DocumentDetails[] => {
    let filteredDocs = allDocumentDetails;

    if (searchQuery) {
      const fuse = new Fuse(allDocumentDetails, {
        shouldSort: true,
        keys: ['name', 'topic', 'fileName', 'requirement.name'],
        threshold: 0.3,
      });

      filteredDocs = fuse.search(searchQuery).map((result) => result.item);
    }

    if (filterBy.value === 'missing') {
      filteredDocs = filteredDocs.filter((d) => isNil(d.document));
    } else if (filterBy.value === 'uploaded') {
      filteredDocs = filteredDocs.filter((d) => !isNil(d.document));
    }

    return filteredDocs;
  }, [allDocumentDetails, filterBy.value, searchQuery]);

  const nonDeletedFilteredDocuments = useMemo(
    () => filteredDocuments.filter((doc) => doc?.document?.status !== 'Deleted'),
    [filteredDocuments],
  );

  const manualSpreadingDocuments = useMemo(
    () =>
      nonDeletedDocuments?.filter(
        (doc) => spreadingBackOfficeMode || doc.document?.status === 'Processed' || doc.document?.status === 'Reviewed',
      ) ?? [],
    [nonDeletedDocuments, spreadingBackOfficeMode],
  );

  const deletedDocumentGroup: DocumentGroup = useMemo(
    () => ({
      title: 'Deleted Documents',
      documents: compact(
        filteredDocuments
          ?.filter((doc) => doc.document?.status === 'Deleted')
          .map(({ document }) => {
            if (!document) {
              return null;
            }

            return {
              id: document.id,
              document,
              fileName: document.filename,
              name: document.description,
              topic: document.topic,
              viewGroup: 'deleted',
            };
          }),
      ),
    }),
    [filteredDocuments],
  );

  const onGroupChange = useCallback((value: GroupingOptions) => {
    setGroupedBy(value);
  }, []);

  const miscellaneousDocumentsGroup: DocumentGroup = useMemo(
    () => ({
      title: 'Miscellaneous Documents',
      documents: compact(nonDeletedFilteredDocuments.filter((d) => d.viewGroup === 'miscellaneous')),
    }),
    [nonDeletedFilteredDocuments],
  );

  const hasListItems = allDocumentDetails?.length || allRequirements?.some((r) => r.sources.uploadFilesSource?.length);

  const DocumentListByGroup = useMemo(() => documentListByGroup[groupedBy], [groupedBy]);

  if (loadingRequirements || loadingDocuments) {
    return <LoadingPage />;
  }

  if (!allDocumentDetails.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={10} px={20}>
      {showDocumentHubSpreadingSummary && nonDeletedDocuments?.length ? (
        <Collapse expanded={Boolean(hasListItems)}>
          <DemoDocumentSummaryV2
            documents={nonDeletedDocuments ?? []}
            applicationId={application.id}
            manualSpreadingDocuments={manualSpreadingDocuments}
          />
        </Collapse>
      ) : null}
      {showDocumentGatheringCard ? <DocumentGatheringCard /> : null}
      <DocumentHubFilters
        documents={allDocumentDetails}
        groupedBy={groupedBy}
        onGroupChange={onGroupChange}
        filterBy={filterBy}
        onFilterChange={setFilterBy}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
      />
      {hasListItems ? (
        <Flex flexDirection={'column'} gap={10}>
          <DocumentListByGroup allRequirements={allRequirements ?? []} documents={nonDeletedFilteredDocuments} />
          {miscellaneousDocumentsGroup.documents?.length ? <DocumentList groups={[miscellaneousDocumentsGroup]} /> : null}
          {deletedDocumentGroup?.documents?.length ? <DocumentList groups={[deletedDocumentGroup]} /> : null}
        </Flex>
      ) : (
        <DocumentHubEmptyState />
      )}
    </Flex>
  );
};
