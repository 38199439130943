import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { useMemo } from 'react';
import type { DocumentDetails } from '../types';
import { isDocumentRelatedToRequirement } from '../../../shared/utils/isDocumentRelatedToRequirement';

export const useDocumentDetails = (allDocuments: DocumentWithIssues[], allRequirements: EvaluatedOpportunityRequirement[]) =>
  useMemo<DocumentDetails[]>(() => {
    if (!allRequirements.length && !allDocuments.length) {
      return [];
    }

    const documentsByRequirementId: [string, DocumentWithIssues[]][] =
      allRequirements?.map((r) => [r.id, allDocuments?.filter((d) => isDocumentRelatedToRequirement(d, r)) ?? []]) ?? [];

    return documentsByRequirementId.flatMap(([requirementId, reqDocuments]) => {
      const requirement = allRequirements?.find((r) => r.id === requirementId);
      const reqTopics = new Set(requirement?.sources.uploadFilesSource?.map((file) => file.topic) ?? []);

      const miscDocumentDetails: DocumentDetails[] =
        reqDocuments
          ?.filter((document) => !reqTopics.has(document.topic))
          .map((document) => ({
            id: document.id,
            fileName: document.filename,
            document,
            topic: document.topic,
            name: document.description,
            viewGroup: 'miscellaneous',
            requirement,
          })) ?? [];

      const requirementDocumentDetails: DocumentDetails[] =
        requirement?.sources.uploadFilesSource?.map(({ name: documentName, topic, dismissDataByEntity }) => {
          const document = reqDocuments?.find((d) => d.topic === topic && d.relatedEntityId === requirement.entityId);

          return {
            id: document?.id ?? requirement.id + topic,
            document,
            fileName: document?.filename ?? '',
            topic,
            name: document?.description ?? documentName,
            dismissed: dismissDataByEntity?.[requirement.entityId],
            viewGroup: 'requirement',
            requirement,
          };
        }) ?? [];

      return [...miscDocumentDetails, ...requirementDocumentDetails];
    });
  }, [allRequirements, allDocuments]);
