import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Flex, Text, greyPalette } from '@lama/design-system';
import type { EnvelopeTracking } from '@lama/docusign-client';
import type { RelatedPersonApiModel, BusinessApiModel } from '@lama/business-service-client';
import { personFullName, businessName as businessNameSelector } from '@lama/selectors';
import { Avatar, LoadingPage } from '@lama/app-components';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useTemplateDocusignDocumentsQuery } from './hooks/useTemplateDocusignDocumentsQuery';
import { EnvelopeStatusChip } from './EnvelopeStatusChip';
import { EnvelopeButton } from './EnvelopeButton';

export const statusesToVoid = new Set(['sent', 'delivered', 'signed', 'completed']);

interface EnvelopeDocusignCardProps {
  people: RelatedPersonApiModel[];
  templateId: string;
  envelopeTracking?: EnvelopeTracking;
  business?: BusinessApiModel;
}

const StyledDocumentsList = styled('ul')`
  padding-left: ${({ theme }) => theme.space[6]}px;
  display: flex;
  flex-direction: column;
  color: ${greyPalette[500]};
`;

const EnvelopeCardTitle: FC<{ people: RelatedPersonApiModel[]; business?: BusinessApiModel }> = ({ people, business }) => {
  const person = useMemo(() => people[0], [people]);

  const { firstName, lastName, fullName } = useMemo(() => {
    if (!person) {
      return { firstName: '', lastName: '', fullName: '-' };
    }
    return { firstName: person.firstName, lastName: person.lastName, fullName: personFullName(person) };
  }, [person]);

  const businessName = useMemo(() => (business ? businessNameSelector(business) : ''), [business]);

  if (people.length > 1) {
    return (
      <Flex gap={2} alignItems={'center'}>
        <Text variant={'h6'}>{`All Guarantors (${people.length})`}</Text>
      </Flex>
    );
  }

  return (
    <Flex gap={2} alignItems={'center'}>
      <Avatar firstName={firstName} lastName={lastName} />
      <Flex flexDirection={'column'}>
        <Text variant={'h6'}>{fullName}</Text>
        <Text variant={'body2'}>{businessName}</Text>
      </Flex>
    </Flex>
  );
};

export const EnvelopeDocusignCard: FC<EnvelopeDocusignCardProps> = ({ people, templateId, envelopeTracking, business }) => {
  const { opportunity } = useContext(ApplicationContext);

  const peopleIds = useMemo(() => people.map((p) => p.id), [people]);

  const { data: documents, isPending: loadingDocuments } = useTemplateDocusignDocumentsQuery(opportunity.id, templateId);

  return (
    <Flex flexDirection={'column'} border={`1px solid ${greyPalette[300]}`} borderRadius={'10px'} p={4} flex={'0 0 calc(50% - 8px)'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <EnvelopeCardTitle people={people} business={business} />
        {envelopeTracking?.status ? <EnvelopeStatusChip status={envelopeTracking.status} /> : null}
      </Flex>
      <Flex flexDirection={'row'} justifyContent={'space-between'} flex={1}>
        {loadingDocuments ? (
          <LoadingPage />
        ) : (
          <StyledDocumentsList>
            {documents?.map((d) => (
              <li key={d.name}>
                <Text variant={'body2'}>{d.name}</Text>
              </li>
            ))}
          </StyledDocumentsList>
        )}
        <Flex flexDirection={'row'} alignItems={'flex-end'}>
          <EnvelopeButton envelopeTracking={envelopeTracking} peopleIds={peopleIds} templateId={templateId} businessId={business?.id} />
        </Flex>
      </Flex>
    </Flex>
  );
};
