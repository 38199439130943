import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import * as selectors from '@lama/selectors';
import { Flex, Text } from '@lama/design-system';
import { applicationBorrowingRelatedBusinessesSelector } from '@lama/selectors';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { RelatedCompanySegment } from './Segments/RelatedCompanySegment';
import { PrincipalSegment } from './Segments/PrincipalSegment';

export const OwnersView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application, properties } = useContext(ApplicationContext);

  const business = useMemo(
    () =>
      applicationBorrowingRelatedBusinessesSelector(application).find(
        ({ business: { id: businessId } }) => businessId === requirement.entityId,
      )?.business,
    [application, requirement.entityId],
  );

  const owningBusinesses = useMemo(
    () => (business ? selectors.owningBusinessesSelectorV2({ application, business }).map(({ owner }) => owner) : []),
    [application, business],
  );
  const people = useMemo(() => (business ? selectors.ownersSelector(business) ?? [] : []), [business]);

  if (!people?.length && !owningBusinesses.length) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Text variant={'body2'}>{'No information found'}</Text>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Flex flexDirection={'column'} gap={4}>
        {people?.map((person) => (
          <PrincipalSegment key={person.id} principal={person} properties={properties} />
        ))}
        {owningBusinesses?.map((owner) => (
          <RelatedCompanySegment key={owner.id} businessId={business?.id} relatedCompany={owner} />
        ))}
      </Flex>
    </RequirementPrintContainer>
  );
};
