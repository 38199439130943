import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { groupBy, mapValues, sortBy } from 'lodash-es';
import type { UnderwritingNote } from '@lama/contracts';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import mixpanel from 'mixpanel-browser';
import { Thread } from '../../../shared/components/Comments/Thread';
import type { NoteRelation } from '../../../shared/utils/getNoteRelatedItems';
import { getNoteRelatedItems } from '../../../shared/utils/getNoteRelatedItems';
import { useOpportunityRequirementsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { ApplicationContext } from '../ApplicationContext';

interface ThreadTitleSuffixComponentProps {
  noteRelation: NoteRelation;
}

const ThreadTitleSuffixComponent: FC<ThreadTitleSuffixComponentProps> = ({ noteRelation }) => {
  if (!noteRelation.tabName && !noteRelation.sectionName) {
    return null;
  }

  return (
    <Flex gap={2} alignItems={'center'}>
      {noteRelation.tabName ? (
        <Text variant={'body2'} color={greyPalette[500]}>
          {noteRelation.tabName}
        </Text>
      ) : null}
      {noteRelation.sectionName ? (
        <Text variant={'body2'} color={greyPalette[500]}>
          {noteRelation.tabName ? '> ' : ''}
          {noteRelation.sectionName}
        </Text>
      ) : null}
    </Flex>
  );
};

export const CommentsList: FC<{ notes: UnderwritingNote[] }> = ({ notes }) => {
  const { product, opportunity } = useContext(ApplicationContext);
  const { data: opportunityRequirements, isFetching } = useOpportunityRequirementsQuery(opportunity.id);

  const sortedThreads = useMemo(() => {
    const groupedByThread = groupBy(notes, 'threadId');

    const sortedNotesByThread = mapValues(groupedByThread, (threadNotes) => sortBy(threadNotes, 'createdAt'));

    return sortedNotesByThread;
  }, [notes]);

  mixpanel.track_pageview({
    applicationId: opportunity.application.id,
    opportunityId: opportunity.id,
    productId: opportunity.productId,
    page: 'comments',
  });

  if (isFetching) {
    <LoadingPage />;
  }

  if (!notes.length) {
    return null;
  }

  return (
    <Flex gap={4} flexDirection={'column'}>
      {Object.entries(sortedThreads).map(([threadId, threadNotes]) => {
        const noteRelation = getNoteRelatedItems(threadNotes[0]!, opportunityRequirements ?? [], product);

        return (
          <Thread
            key={threadId}
            threadNotes={threadNotes}
            threadId={threadId}
            relatedItemId={noteRelation.relatedItemId}
            relatedItemType={noteRelation.relatedItemType}
            threadTitleSuffixComponent={<ThreadTitleSuffixComponent noteRelation={noteRelation} />}
          />
        );
      })}
    </Flex>
  );
};
