import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import { Text } from '@lama/design-system';
import { grey } from '@mui/material/colors';
import * as selectors from '@lama/selectors';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import InfoIcon from '../assets/info.svg';
import HelpIcon from '../assets/help.svg';
import AuthorizationIcon from '../assets/authorization.svg';
import { ApplicationContext } from '../../Application/ApplicationContext';

const dashedBorderStyle = {
  backgroundImage:
    'repeating-linear-gradient(0deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px), repeating-linear-gradient(90deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px), repeating-linear-gradient(180deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px), repeating-linear-gradient(270deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px)',
  backgroundSize: '0px 100%, 100% 0px, 0px 100% , 100% 1px',
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  backgroundRepeat: 'no-repeat',
};

export const DashedBorder: FC = () => <Box width={'100%'} height={'1px'} style={{ ...dashedBorderStyle }} zIndex={'-1'} />;

export const ImportantInformation: FC = () => (
  <Stack gap={2}>
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <InfoIcon />
      <Typography sx={{ fontFamily: 'Ubuntu', fontSize: '14px', fontHeight: 500, lineHeight: '18px', textAlign: 'left' }}>
        {'Important Information About Procedures for Opening a New Account'}
      </Typography>
    </Stack>
    <Text weight={400} size={12} color={grey[500]} paragraph>
      {
        'To help the government fight the funding of terrorism and money laundering activites, Federal law requires all financial institutions to obtain,verify, and record information that identifies each person who opens an account.'
      }
    </Text>
  </Stack>
);

const Meaning: FC = () => (
  <Stack gap={2}>
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <HelpIcon />
      <Typography sx={{ fontFamily: 'Ubuntu', fontSize: '14px', fontHeight: 500, lineHeight: '18px', textAlign: 'left' }}>
        {'What this means to you:'}
      </Typography>
    </Stack>
    <Text weight={400} size={12} color={grey[500]} paragraph>
      {
        "When you open an account we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may alsoask to see your driver's license or other identifying documents."
      }
    </Text>
  </Stack>
);

const Authorization: FC = () => (
  <Stack gap={2}>
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <AuthorizationIcon />
      <Typography sx={{ fontFamily: 'Ubuntu', fontSize: '14px', fontHeight: 500, lineHeight: '18px', textAlign: 'left' }}>
        {'Authorization:'}
      </Typography>
    </Stack>
    <Text weight={400} size={12} color={grey[500]} paragraph>
      {
        "I hereby apply for the loan or credit described in this application. I certify that I made no misrepresentations in this loan application or in anyrelated documents, that all information is true and complete, and that I did not omit any important information. I agree that any property securingthe loan or credit will not be used for any illegal or restricted purpose. Lender is authorized to verify with other parties and to make anyinvestigation of my credit, either directly or through any agency employed by Lender for that purpose. Lender may disclose to any otherinterested parties information as to Lender's experiences or transactions with my account. I understand that Lender will retain this application andany other credit information Lender receives, even if no loan or credit is granted. These representations and authorizations extend not only toLender, but also to any insurer of the loan and to any investor to whom Lender may sell all or any part of the loan. I further authorize Lender toprovide to any such insurer or investor any information and documentation that they may receive with respect to my application, credit or loan."
      }
    </Text>
  </Stack>
);

const DashedLineWithHelperText: FC<{ helperText: string }> = ({ helperText }) => (
  <Stack gap={1}>
    <DashedBorder />
    <Typography variant={'subtitle1'} color={grey[500]} fontSize={'12px'}>
      {helperText}
    </Typography>
  </Stack>
);

const BusinessSignature: FC<{ businessName: string }> = ({ businessName }) => (
  <Stack gap={5}>
    <Stack gap={1}>
      <Typography variant={'h4'} fontSize={'16px'}>
        {'Borrower'}
      </Typography>
      <Typography variant={'body1'} color={grey[500]}>
        {businessName}
      </Typography>
    </Stack>
    <Stack gap={4}>
      <DashedLineWithHelperText helperText={'Signature'} />
      <DashedLineWithHelperText helperText={'Title'} />
      <DashedLineWithHelperText helperText={'Date'} />
    </Stack>
  </Stack>
);

const GuarantorSignature: FC<{ guarantor: RelatedPersonApiModel; index: number }> = ({ guarantor, index }) => {
  const fullName = selectors.personFullName(guarantor);

  return (
    <Stack gap={5} sx={{ pageBreakInside: 'avoid' }}>
      <Stack gap={1}>
        <Typography variant={'h4'} fontSize={'16px'}>
          {`Guarantor ${index + 1}`}
        </Typography>
        <Typography variant={'body1'} color={grey[500]}>
          {fullName}
        </Typography>
      </Stack>
      <Stack gap={4}>
        <DashedLineWithHelperText helperText={'Signature'} />
        <DashedLineWithHelperText helperText={'Date'} />
      </Stack>
    </Stack>
  );
};

export const ApplicationDisclaimer: FC = () => {
  const { application } = useContext(ApplicationContext);

  const guarantors = useMemo(() => selectors.applicationGuarantorsSelector(application), [application]);
  const borrowingBusinessNames = useMemo<string[]>(
    () => selectors.applicationBorrowingRelatedBusinessesSelector(application).map((b) => selectors.businessName(b.business) ?? ''),
    [application],
  );

  return (
    <Stack p={2} sx={{ display: 'block', pageBreakBefore: 'always', printColorAdjust: 'exact', WebkitPrintColorAdjust: 'exact' }}>
      <Stack gap={5}>
        <Stack direction={'column'} gap={3}>
          <ImportantInformation />
          <Meaning />
          <Authorization />
        </Stack>
        <Grid container gap={3}>
          {borrowingBusinessNames.map((businessName) => (
            <Grid key={businessName} item xs={12}>
              <BusinessSignature businessName={businessName ?? ''} />
            </Grid>
          ))}
          <Grid item xs={12}>
            {null}
          </Grid>
          {guarantors?.map((guarantor, index) => (
            <Grid key={guarantor.id} item xs={5}>
              <GuarantorSignature guarantor={guarantor} index={index} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};
