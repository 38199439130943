import type { FC } from 'react';
import React from 'react';
import { Flex, Text, Button, greyPalette } from '@lama/design-system';
import { format } from 'date-fns';
import { ReceivedFilesIcon } from '../../../../../../shared/components/Icons/index.js';

interface OrderUccFillingsBoxProps {
  liensFound: number;
  searchedAt?: string;
  onPullDocumentsClick: () => void;
}
export const OrderUccFillingsBox: FC<OrderUccFillingsBoxProps> = ({ liensFound, searchedAt, onPullDocumentsClick }) => {
  const formattedSearchedAtDate = searchedAt ? format(new Date(searchedAt), 'MM/dd/yyyy HH:mm') : null;
  return (
    <Flex border={'1px solid'} borderColor={greyPalette[200]} py={6} width={'100%'} borderRadius={4} justifyContent={'space-between'} p={4}>
      <Flex gap={4}>
        <ReceivedFilesIcon color={greyPalette[600]} />
        <Flex flexDirection={'column'}>
          <Text variant={'body2'}>{`${liensFound} Liens Found`}</Text>
          {formattedSearchedAtDate ? (
            <Text variant={'body4'} color={'secondary'}>
              {`searched at ${formattedSearchedAtDate}`}
            </Text>
          ) : null}
        </Flex>
      </Flex>
      <Button variant={'tertiary'} onClick={onPullDocumentsClick}>
        {'Order UCC Fillings'}
      </Button>
    </Flex>
  );
};
