import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import type { BusinessApiModel } from '@lama/business-service-client';
import { isNil } from 'lodash-es';
import type { ClosingTaskComponentProps } from '../closingTaskComponentProps.js';
import { AdditionalDocumentsSection } from '../../ClosingTaskCard.js';
import { useRequestMiddeskDocumentsMutation } from './hooks/useRequestMiddeskDocumentsMutation.js';
import { useMiddeskReportTrackingQuery } from './hooks/useMiddeskReportTrackingQuery.js';
import { MiddeskUCCBusinessTaskComponent } from './MiddeskUCCBusinessTaskComponent.js';
import { MiddeskRegistrationBusinessTaskComponent } from './MiddeskRegistrationBusinessTaskComponent.js';

export const MiddeskBusinessTaskComponent: FC<ClosingTaskComponentProps & { business: BusinessApiModel }> = ({
  task,
  taskDocuments,
  business,
}) => {
  const { mutateAsync: requestMiddeskDocuments } = useRequestMiddeskDocumentsMutation();

  const [shouldFetchTracking, setShouldFetchTracking] = useState(false);
  const allowMiddeskPull = useMemo(
    () => !isNil(business.verifications?.middesk?.searchResult) && !isNil(task.taskParams?.documentType),
    [business.verifications?.middesk?.searchResult, task.taskParams?.documentType],
  );

  const { data: businessMiddeskReportTracking } = useMiddeskReportTrackingQuery(business.id, {
    enabled: allowMiddeskPull,
    ...(shouldFetchTracking && allowMiddeskPull ? { refetchInterval: 5000 } : {}),
  });

  const currentDocumentType = useMemo(() => task.taskParams?.documentType, [task.taskParams?.documentType]);

  const currentOrderType = useMemo(() => (currentDocumentType === 'liens' ? 'liens' : 'documents'), [currentDocumentType]);

  const TaskComponent = useMemo(
    () => (currentOrderType === 'liens' ? MiddeskUCCBusinessTaskComponent : MiddeskRegistrationBusinessTaskComponent),
    [currentOrderType],
  );

  const orderTracking = useMemo(
    () =>
      businessMiddeskReportTracking?.ordersTracking?.find(
        (order) => order.orderType === currentOrderType && order.relatedClosingTaskId === task.id,
      ),
    [businessMiddeskReportTracking?.ordersTracking, currentOrderType, task.id],
  );

  const orderStatus = useMemo(() => orderTracking?.status, [orderTracking?.status]);

  const searchOrder = useMemo(
    () =>
      businessMiddeskReportTracking?.ordersTracking?.find(
        (order) => order.orderType === currentOrderType && order.relatedClosingTaskId === task.id,
      )?.searchOnly,
    [businessMiddeskReportTracking?.ordersTracking, currentOrderType, task.id],
  );

  const middeskDocumentsTracking = useMemo(
    () => businessMiddeskReportTracking?.documentsTracking?.filter(({ documentId }) => task.documentIds?.includes(documentId)) ?? [],
    [businessMiddeskReportTracking?.documentsTracking, task.documentIds],
  );

  const businessDocuments = useMemo(
    () => taskDocuments?.filter((document) => document.relatedEntityId === business.id) ?? [],
    [taskDocuments, business.id],
  );

  useEffect(() => {
    if (orderStatus === 'completed') {
      setShouldFetchTracking(false);
    }
    if (orderStatus === 'created' || orderStatus === 'pending') {
      setShouldFetchTracking(true);
    }
  }, [orderStatus]);

  const onPullDocumentsClick = useCallback(async () => {
    await requestMiddeskDocuments({
      relatedClosingTaskId: task.id,
      businessId: business.id,
      documentType: currentDocumentType,
    });
    setShouldFetchTracking(true);
  }, [business.id, currentDocumentType, requestMiddeskDocuments, task.id]);

  return (
    <Flex key={business.id} flexDirection={'column'} gap={2} flex={1}>
      <TaskComponent
        taskId={task.id}
        searchOrder={searchOrder}
        orderTracking={orderTracking}
        businessTaskDocuments={businessDocuments}
        middeskDocumentsTracking={middeskDocumentsTracking}
        business={business}
        allowMiddeskPull={allowMiddeskPull}
        orderDocuments={onPullDocumentsClick}
        onLoadingStatusChange={setShouldFetchTracking}
      />
      <AdditionalDocumentsSection
        taskId={task.id}
        documents={businessDocuments ?? []}
        relatedEntityId={business.id}
        relatedEntityType={'business'}
        taskDocumentRequirements={orderStatus === 'search' ? [] : task.requiredDocuments}
      />
    </Flex>
  );
};
