import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { Flex, greyPalette, Grid, Text } from '@lama/design-system';
import {
  FormikAutoSave,
  FormikDatePicker,
  FormikMoneyInputField,
  FormikPicker,
  FormikYesNoQuestionPicker,
  HideOnPrintWrapper,
  PropertyFormikInput,
  displayToast,
} from '@lama/app-components';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { t } from 'i18next';
import type { ApplicationUpdateApiModel, OpportunityUpdateApiModel } from '@lama/clients';
import * as yup from 'yup';
import { dateValidation } from '@lama/yup-validations';
import { proposedLoanAmount, PRIME_RATE } from '@lama/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConfirmLeaveWithUnsavedChanges } from '../../../../../../shared/components/ConfirmLeaveWithUnsavedChanges';
import { useUpdateOpportunityMutation } from '../../../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { ApplicationContext } from '../../../../ApplicationContext';
import { getRateType, FIXED_RATE_TYPE, PRIME_RATE_TYPE, rateTypes } from '../../../../shared/LoanTerms/termsUtils';
import { SaveFormButton } from '../../../../shared/SaveFormButton';
import { useUpdateApplicationMutation } from '../../../../../../shared/hooks/react-query/application/useUpdateApplication';

const loanTypes = ['Score & Go', 'Traditional Small Loan', 'Change of Ownership', 'New Money'];

const validationSchema = yup.object().shape({
  originationDate: dateValidation,
});

const reviewYearOptions = Array.from({ length: 5 }, (_, i) => (new Date().getFullYear() - i).toString());

export const GrasshopperLoanTermsTermsSegment: FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const { autoSaveEnabled } = useFlags();
  const { mutateAsync: updateOpportunity, isPending: updatingOpportunity } = useUpdateOpportunityMutation(opportunity.id);
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id);

  const initialValues = useMemo(
    () => ({
      spread: opportunity.terms?.rate?.spread?.toString() ?? '',
      benchmarkRateType: getRateType(opportunity.terms?.rate) ?? '',
      term: opportunity.terms?.termLength?.toString() ?? '',
      loanSoldOnSecondaryMarket: opportunity.underwriting?.additionalData?.loanSoldOnSecondaryMarket,
      sbaGuarantyPercentage: opportunity.underwriting?.additionalData?.sbaGuarantyPercentage?.toString() ?? '',
      annualServicingPeriod: opportunity.annualServicingPeriod ?? '',
      externalLoanId: opportunity.externalLoanId ?? '',
      originationDate: opportunity.originationDate ?? '',
      referenceYear: opportunity.referenceYear.toString() ?? '',
      loanType: opportunity.loanType ?? '',
      approvedLoanAmount: proposedLoanAmount(application)?.toString() ?? '',
      isPolicyException: opportunity.underwriting?.isPolicyException,
      prepaymentPenalty: application.prepaymentPenalty ?? '',
      primaryPaymentSource: application.primaryPaymentSource ?? '',
      secondaryPaymentSource: application.secondaryPaymentSource ?? '',
      tertiaryPaymentSource: application.tertiaryPaymentSource ?? '',
      amortizedTermInMonths: application.amortizedTermInMonths?.toString() ?? '',
    }),
    [
      opportunity.terms?.rate,
      opportunity.terms?.termLength,
      opportunity.underwriting?.additionalData?.loanSoldOnSecondaryMarket,
      opportunity.underwriting?.additionalData?.sbaGuarantyPercentage,
      opportunity.underwriting?.isPolicyException,
      opportunity.annualServicingPeriod,
      opportunity.externalLoanId,
      opportunity.originationDate,
      opportunity.referenceYear,
      opportunity.loanType,
      application,
    ],
  );

  const onSubmit = useCallback(
    async (values: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      resetForm({ values });
      const {
        spread,
        benchmarkRateType,
        term,
        loanSoldOnSecondaryMarket,
        sbaGuarantyPercentage,
        annualServicingPeriod,
        externalLoanId,
        originationDate,
        referenceYear,
        loanType,
        approvedLoanAmount,
        isPolicyException,
        prepaymentPenalty,
        primaryPaymentSource,
        secondaryPaymentSource,
        tertiaryPaymentSource,
        amortizedTermInMonths,
      } = values;
      const updateOpportunityPayload: OpportunityUpdateApiModel = {
        underwriting: {
          isPolicyException,
          additionalData: {
            sbaGuarantyPercentage: sbaGuarantyPercentage ? Number(sbaGuarantyPercentage) : null,
            loanSoldOnSecondaryMarket,
          },
        },
        annualServicingPeriod,
        externalLoanId,
        originationDate: originationDate ?? '',
        terms: {
          rate: {
            isFixed: benchmarkRateType === FIXED_RATE_TYPE,
            benchmarkRateType,
            benchmarkRate: benchmarkRateType === PRIME_RATE_TYPE ? PRIME_RATE : 0,
            spread: spread ? Number(spread) : null,
          },
          termLength: term ? Number(term) : null,
          approvedLoanAmount:
            approvedLoanAmount && approvedLoanAmount !== initialValues.approvedLoanAmount ? Number(approvedLoanAmount) : null,
        },
        referenceYear: referenceYear ? Number(referenceYear) : undefined,
        loanType,
      };

      await updateOpportunity(updateOpportunityPayload, {
        onError: () => {
          displayToast('There was a problem processing the request. Please try again.', 'error');
        },
      });

      const updateApplicationPayload: ApplicationUpdateApiModel = {
        prepaymentPenalty,
        primaryPaymentSource,
        secondaryPaymentSource,
        tertiaryPaymentSource,
        amortizedTermInMonths: amortizedTermInMonths ? Number(amortizedTermInMonths) : null,
      };

      await updateApplication(
        { updateApplicationPayload },
        {
          onError: () => {
            displayToast('There was a problem processing the request. Please try again.', 'error');
          },
        },
      );
    },
    [initialValues.approvedLoanAmount, updateApplication, updateOpportunity],
  );

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'body1'} color={greyPalette[500]}>
        {'Terms'}
      </Text>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ dirty, isValid, values, handleSubmit }) => (
          <ConfirmLeaveWithUnsavedChanges dirty={dirty}>
            <Flex flexDirection={'column'} gap={4}>
              <Grid columns={2}>
                <FormikPicker name={'loanType'} label={'Loan Type'} values={loanTypes} />
                <FormikMoneyInputField name={'approvedLoanAmount'} label={'Proposed Loan Amount'} />
                <PropertyFormikInput name={'annualServicingPeriod'} label={'Annual Servicing Period'} />
                <PropertyFormikInput name={'externalLoanId'} label={'External Loan Number'} />
                <HideOnPrintWrapper>
                  <FormikDatePicker name={'originationDate'} label={'Origination Date'} />
                </HideOnPrintWrapper>
                <PropertyFormikInput type={'number'} label={'SBA Guaranty Percentage'} name={'sbaGuarantyPercentage'} />
                <FormikYesNoQuestionPicker name={'loanSoldOnSecondaryMarket'} label={'Loan Sold on Secondary Market'} />
                <PropertyFormikInput type={'number'} label={'Length of Stream'} name={'term'} />
                <FormikPicker name={'benchmarkRateType'} label={'Rate Type'} values={rateTypes} />
                <PropertyFormikInput
                  name={'spread'}
                  label={values.benchmarkRateType === FIXED_RATE_TYPE ? 'Rate' : 'Spread'}
                  type={'number'}
                />
                <FormikPicker
                  label={'Review Year'}
                  name={'referenceYear'}
                  values={reviewYearOptions}
                  helperText={`${t('global.helperText.reviewYear')}`}
                />
                <FormikYesNoQuestionPicker name={'isPolicyException'} label={'Policy Exception'} />
                <PropertyFormikInput name={'prepaymentPenalty'} label={'Prepayment Penalty'} />
                <PropertyFormikInput name={'primaryPaymentSource'} label={'Primary Payment Source'} />
                <PropertyFormikInput name={'secondaryPaymentSource'} label={'Secondary Payment Source'} />
                <PropertyFormikInput name={'tertiaryPaymentSource'} label={'Tertiary Payment Source'} />
                <PropertyFormikInput name={'amortizedTermInMonths'} label={'Amortized Term (Months)'} type={'number'} />
              </Grid>
              {autoSaveEnabled ? (
                <FormikAutoSave />
              ) : (
                <SaveFormButton loading={updatingOpportunity || updatingApplication} submit={handleSubmit} disabled={!dirty || !isValid} />
              )}
            </Flex>
          </ConfirmLeaveWithUnsavedChanges>
        )}
      </Formik>
    </Flex>
  );
};
