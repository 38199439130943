import type { FC } from 'react';
import React, { useContext, useMemo, useCallback, useState, useRef } from 'react';
import { Command } from 'cmdk';
import { Dialog } from '@mui/material';
import { Flex, Input, Text } from '@lama/design-system';
import { intersection, uniq } from 'lodash-es';
import { LoadingPage, EmptySearchState } from '@lama/app-components';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useGetPaginatedPipelineQuery } from '../../../shared/hooks/react-query/pipeline/useGetPaginatedPipelineQuery';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { SearchIcon } from '../../../shared/components/Icons/SearchIcon';
import { ApplicationSearchContext } from './ApplicationSearchContext';
import type { SearchResult } from './types';
import { ApplicationSearchResult } from './ApplicationSearchResult';
import { pipelineItemToSearchResult } from './searchResultTransformers';

import './search.css';

export const ApplicationSearch: FC = () => {
  const { partner: userPartner, partnerPermissions } = useContext(UserDetailsContext);
  const { modalOpen, setModalOpen, recentResults } = useContext(ApplicationSearchContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  useHotkeys(
    'mod+k',
    () => {
      setModalOpen((prev) => !prev);
      setTimeout(() => {
        setSearchTerm('');
      }, 200);
    },
    { enableOnFormTags: true },
  );

  const userPartners = useMemo(() => {
    const res = uniq([
      ...(userPartner ? [userPartner] : []),
      ...(partnerPermissions
        ? Object.entries(partnerPermissions)
            .filter(([, value]) => intersection(value, ['read', 'write']).length > 0)
            .map(([key]) => key)
        : []),
    ]);

    return res;
  }, [userPartner, partnerPermissions]);

  const { data: pipelineSearchResult, isPending: loadingItems } = useGetPaginatedPipelineQuery({
    partnerIds: userPartners,
    limit: 15,
    search: searchTerm,
  });

  const searchResults: SearchResult[] = useMemo(() => {
    if (!pipelineSearchResult) {
      return [];
    }

    return pipelineSearchResult.pipeline.map((item) => pipelineItemToSearchResult(item));
  }, [pipelineSearchResult]);

  const handleClose = useCallback(() => {
    setModalOpen(false);
    setTimeout(() => {
      setSearchTerm('');
    }, 200);
  }, [setModalOpen]);

  const onResultSelected = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onItemSelect = useCallback(
    (value: string) => {
      handleClose();
      navigate(`/pipeline/${value}`);
    },
    [handleClose, navigate],
  );

  const focusInput = () => {
    if (modalOpen && inputRef?.current && !inputRef.current.hidden) {
      inputRef.current.focus();
    }
  };

  const { showSearchResult, showRecentApplications, showEmptyState } = useMemo(
    () => ({
      showSearchResult: !loadingItems && searchTerm && searchResults.length,
      showRecentApplications: !loadingItems && !searchTerm && recentResults.length,
      showEmptyState: !loadingItems && ((searchTerm && !searchResults.length) || (!searchTerm && !recentResults.length)),
    }),
    [loadingItems, searchTerm, searchResults.length, recentResults.length],
  );

  const debouncedSearch = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, 350);

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      TransitionProps={{
        onEntered: focusInput,
      }}
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flexEnd', alignItems: 'center', overflow: 'scroll' }}
      scroll={'paper'}
      fullWidth
      PaperProps={{
        elevation: 8,
        sx: {
          width: '600px',
          position: 'fixed',
          top: '0',
          alignSelf: 'center',
          padding: '16px',
          minHeight: '280px',
          maxHeight: '420px',
          borderRadius: '8px',
        },
      }}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
    >
      <Flex className={'search'} flex={1}>
        <Command shouldFilter={false}>
          <Flex mb={3} flex={1}>
            <Input
              ref={inputRef}
              $size={'m'}
              startIcon={<SearchIcon />}
              placeholder={'Search Applications'}
              onChange={debouncedSearch}
              fullWidth
            />
          </Flex>
          <Command.List>
            {loadingItems ? (
              <Command.Loading>
                <LoadingPage spinnerSize={'l'} />
              </Command.Loading>
            ) : null}
            {showSearchResult ? (
              <Command.Group>
                {searchResults.map((item) => (
                  <Command.Item key={item.opportunityId} onSelect={onItemSelect} value={item.opportunityId}>
                    <ApplicationSearchResult searchResult={item} onItemClick={onResultSelected} />
                  </Command.Item>
                ))}
              </Command.Group>
            ) : null}
            {showRecentApplications ? (
              <>
                <Text pl={2} variant={'body3'} color={'secondary'}>
                  {'Recent Applications'}
                </Text>
                <Command.Group>
                  {recentResults.map((item) => (
                    <Command.Item key={`recent-${item.opportunityId}`} onSelect={onItemSelect} value={item.opportunityId}>
                      <ApplicationSearchResult searchResult={item} onItemClick={onResultSelected} />
                    </Command.Item>
                  ))}
                </Command.Group>
              </>
            ) : null}
            {showEmptyState ? (
              <Command.Empty>
                <EmptySearchState searchTerm={searchTerm} initialText={'Start typing to find applications'} />
              </Command.Empty>
            ) : null}
          </Command.List>
          {showSearchResult ? (
            <Flex px={2} pt={3}>
              <Text variant={'body3'} color={'secondary'}>
                {`Showing ${pipelineSearchResult?.pipeline.length} out of ${pipelineSearchResult?.total}`}
              </Text>
            </Flex>
          ) : null}
        </Command>
      </Flex>
    </Dialog>
  );
};
