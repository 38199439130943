import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { DocumentPreviewModal } from '@lama/app-components';
import type { GetDataPath, GridOptions, ProcessCellForExportParams, RowClassParams } from '@ag-grid-community/core';
import { Flex, Text } from '@lama/design-system';
import type { Period } from '@lama/selectors';
import type { SpreadRow } from '@lama/spreads-generator-client';
import { getPeriodsWithFinancials, loanDisplayNameSelector } from '@lama/selectors';
import type { OpportunityUpdateApiModel } from '@lama/clients';
import { useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { sentenceCase } from 'change-case-all';
import type { SpreadUnitType } from '../types';
import { getAdjustmentNotesColumn, getSpreadTableColumnDefs } from '../utils/getSpreadColumns';
import { useApplicationDocumentsQuery } from '../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { useUpdateOpportunityMutation } from '../../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { useUsersByPartnerQuery } from '../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { formatCellValueWithHierarchyIndentation, formatSpreadCellValue } from '../utils/formatSpreadCellValue';
import ExportSpreadIcon from '../assets/exportSpreadIcon';
import { useExportAGGridToExcel } from '../hooks/useExportAGGridToExcel';
import type { SpreadTableRef } from '../hooks/types';
import { useGenerateSpreadQuery } from '../hooks/useGenerateSpreadQuery';
import { getSpreadRows } from '../utils/getSpreadRows';
import { SpreadSettingsButton } from './SpreadSettings/SpreadSettingsButton';
import { getDisplayedPeriods } from './getDisplayedPeriods';
import type { GridContext, OpenDocumentParams, SpreadTableProps } from './SpreadTable/types';
import { PrintSpreadTable } from './PrintSpreadTable/PrintSpreadTable';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

export const SpreadTableInner = forwardRef<SpreadTableRef, SpreadTableProps>(
  (
    {
      applicationId,
      opportunity,
      spreadsSettings,
      spreadConfig: { id: spreadId, tableConfig, spreadYears },
      entities,
      properties,
      financials,
      printMode,
      spreadName,
      periodDisplayFormat = 'income statement',
      showEntityNames = true,
      title,
      onGridReady,
    },
    spreadTableRef,
  ) => {
    const queryClient = useQueryClient();
    const { data: documents } = useApplicationDocumentsQuery({ applicationId });
    const { data: users } = useUsersByPartnerQuery(opportunity.partnerId);
    const [openDocument, setOpenDocument] = useState<OpenDocumentParams | null>();
    const currentDocument = useMemo(() => documents?.find((doc) => doc.id === openDocument?.documentId), [documents, openDocument]);
    const [spreadUnitType, setSpreadUnitType] = useState<SpreadUnitType>('decimal');

    const { exportSpreadsToExcel, showFormTypeInSpreadColumnHeader, generateSpreadInBackend } = useFlags();

    const spreadSource = useMemo(
      () => (!!printMode || !generateSpreadInBackend ? 'frontend' : 'backend'),
      [generateSpreadInBackend, printMode],
    );

    const displayedPeriods = useMemo(() => {
      const defaultYearColumns = spreadYears ?? [opportunity.referenceYear];
      return getDisplayedPeriods(financials ?? [], spreadsSettings ?? [], spreadName, defaultYearColumns);
    }, [spreadYears, opportunity.referenceYear, financials, spreadsSettings, spreadName]);

    const { data: generatedSpread, isFetching: isGeneratingSpread } = useGenerateSpreadQuery(
      {
        entityIdsByType: {
          application: entities.application.map((a) => a.id),
          business: entities.business.map((b) => b.business.id),
          opportunity: entities.opportunity.map((o) => o.id),
          person: entities.person.map((p) => p.person.id),
        },
        opportunityId: opportunity.id,
        periods: displayedPeriods,
        showEntityNames,
        spreadId,
      },
      { enabled: spreadSource === 'backend' },
    );

    const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunity.id, {
      onMutate: async (updateOpportunityPayload: OpportunityUpdateApiModel) => {
        await queryClient.cancelQueries({ queryKey: ['opportunity', opportunity.id] });

        const previousOpportunity = queryClient.getQueryData(['opportunity', opportunity.id]);

        queryClient.setQueryData(['opportunity', opportunity.id], (oldOpportunity: any) => ({
          ...oldOpportunity,
          ...updateOpportunityPayload,
        }));

        return { previousOpportunity, updateOpportunityPayload };
      },
    });

    const gridContext: GridContext = useMemo(
      () => ({
        applicationId,
      }),
      [applicationId],
    );

    const allPeriods: Period[] = useMemo(() => getPeriodsWithFinancials(financials ?? []).map((p) => p.period), [financials]);

    const onOpenDocument = useCallback(
      (openDocumentParams: OpenDocumentParams) => {
        const documentToOpen = documents?.find((doc) => doc.id === openDocumentParams?.documentId);
        if (!documentToOpen?.previewUrl?.toLowerCase().includes('.pdf')) {
          open(documentToOpen?.previewUrl);
          return;
        }
        setOpenDocument(openDocumentParams);
      },
      [documents],
    );

    const onCloseDocument = useCallback(() => {
      setOpenDocument(null);
    }, []);

    const spreadRowsFromUI: SpreadRow[] = useMemo(
      () =>
        spreadSource === 'frontend'
          ? getSpreadRows({
              entities,
              financials,
              properties,
              displayedPeriods,
              spreadConfigurations: tableConfig,
              showEntityNames,
            })
          : [],
      [spreadSource, entities, financials, properties, displayedPeriods, tableConfig, showEntityNames],
    );

    const spreadRows = useMemo(
      () => (spreadSource === 'backend' ? generatedSpread?.spreadRows : spreadRowsFromUI),
      [spreadSource, generatedSpread?.spreadRows, spreadRowsFromUI],
    );

    // no need to recalculate this as long as we're not re-configuration the years or adding columns live.
    const columnDefs = useMemo(
      () => [
        ...getSpreadTableColumnDefs(
          applicationId,
          onOpenDocument,
          displayedPeriods,
          spreadRows ?? [],
          periodDisplayFormat,
          spreadUnitType,
          !!showFormTypeInSpreadColumnHeader,
        ),
        getAdjustmentNotesColumn(displayedPeriods, users ?? [], opportunity.underwriting?.notes ?? []),
      ],
      [
        applicationId,
        onOpenDocument,
        displayedPeriods,
        spreadRows,
        periodDisplayFormat,
        spreadUnitType,
        showFormTypeInSpreadColumnHeader,
        users,
        opportunity.underwriting?.notes,
      ],
    );
    const getRowStyles = useCallback((rowParams: RowClassParams<SpreadRow>) => {
      const shouldHighlight = rowParams.data?.propertyConfig.highlight;
      return {
        fontWeight: shouldHighlight ? 'bold' : 'normal',
      };
    }, []);

    const onSetSelectedPeriods = useCallback(
      (periods: Period[]) => {
        updateOpportunity({
          spreadsSettings: [
            ...(spreadsSettings?.filter((p) => p.spreadName !== spreadName) ?? []),
            { spreadName, selectedPeriods: periods.map(({ startDate, endDate }) => ({ startDate, endDate })) },
          ],
        });
      },
      [spreadName, spreadsSettings, updateOpportunity],
    );

    const getDataPath: GetDataPath<SpreadRow> = useCallback((data) => data.attributeHierarchy, []);

    const gridOptions: GridOptions = useMemo(
      () => ({
        context: gridContext,
        rowData: spreadRows,
        tooltipTrigger: 'focus',
        domLayout: printMode ? 'print' : 'autoHeight',
        tooltipShowDelay: 1000,
        tooltipHideDelay: 120_000,
        getDataPath,
        getRowStyle: getRowStyles,
        enableRangeSelection: false,
        suppressMultiSort: true,
        defaultColDef: {
          sortable: false,
          menuTabs: [],
          suppressMovable: true,
        },
        autoGroupColumnDef: {
          headerName: '',
          minWidth: 250,
          pinned: 'left',
          cellRendererParams: {
            suppressCount: true,
          },
          cellClassRules: {
            highlightRow: (params) => params.node?.data?.propertyConfig?.highlight,
          },
        },
        groupDefaultExpanded: -1,
        tooltipInteraction: true,
        treeData: true,
        excelStyles: [
          {
            id: 'highlightRow',
            font: { bold: true },
          },
          {
            id: 'adjustedValue',
            font: { color: '#090561', italic: true },
          },
          {
            id: 'header',
            font: { bold: true },
          },
        ],
        defaultExcelExportParams: {
          allColumns: true,
          columnWidth: 150,
          autoConvertFormulas: true,
          processHeaderCallback: (params) => {
            if (params.column.getColId() === 'ag-Grid-AutoColumn') {
              return 'Line Item';
            }
            return params.column.getColDef().headerName ?? '';
          },
          processCellCallback: (params: ProcessCellForExportParams<SpreadRow>) => {
            if (params.column.getColId() === 'ag-Grid-AutoColumn') {
              const cellHierarchy = params.node?.data?.attributeHierarchy ?? [];
              return formatCellValueWithHierarchyIndentation(params.value, cellHierarchy.length - 1);
            }

            return formatSpreadCellValue(params.value, params.node?.data?.property.type);
          },
        },
        onGridReady,
      }),
      [gridContext, spreadRows, printMode, getDataPath, getRowStyles, onGridReady],
    );

    const fileNameForExports = useMemo(
      () => `${loanDisplayNameSelector(opportunity.application)} - ${sentenceCase(spreadName)}`,
      [opportunity, spreadName],
    );
    const { exportSpread, gridRef } = useExportAGGridToExcel(spreadTableRef, fileNameForExports, spreadName);

    useEffect(() => {
      if (isGeneratingSpread) {
        gridRef.current?.api?.showLoadingOverlay();
      } else {
        gridRef.current?.api?.hideOverlay();
      }
    }, [gridRef, isGeneratingSpread]);

    if (!financials) {
      return null;
    }

    return (
      <Flex flexDirection={'column'} gap={3} height={'100%'}>
        <Flex flexDirection={'row'} justifyContent={title ? 'space-between' : 'flex-end'} alignItems={'center'}>
          {title ? <Text variant={'h6'}>{title}</Text> : null}
          <Flex gap={2}>
            {exportSpreadsToExcel ? (
              <Tooltip title={'Export to Excel'}>
                <IconButton onClick={exportSpread} size={'small'}>
                  <ExportSpreadIcon color={grey[500]} />
                </IconButton>
              </Tooltip>
            ) : null}
            <SpreadSettingsButton
              allPeriods={allPeriods}
              currentPeriods={displayedPeriods}
              onSetSelectedPeriods={onSetSelectedPeriods}
              spreadUnitType={spreadUnitType}
              setSpreadUnitType={setSpreadUnitType}
              periodDisplayFormat={periodDisplayFormat}
            />
          </Flex>
        </Flex>
        <AgGridReact ref={gridRef} className={'ag-theme-alpine'} gridOptions={gridOptions} rowData={spreadRows} columnDefs={columnDefs} />
        <DocumentPreviewModal
          onClose={onCloseDocument}
          open={!!openDocument?.documentId && !!currentDocument?.downloadUrl?.includes('.pdf')}
          initialPage={openDocument?.pageIndex}
          initialHighlight={openDocument?.boundingBox}
          document={currentDocument}
        />
      </Flex>
    );
  },
);

export const SpreadTable = forwardRef<SpreadTableRef, SpreadTableProps>(({ printMode, ...restOfProps }, ref) => {
  if (printMode) {
    return <PrintSpreadTable printMode={printMode} {...restOfProps} />;
  }

  return <SpreadTableInner ref={ref} printMode={printMode} {...restOfProps} />;
});
