/* eslint-disable react/jsx-no-bind */
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { getRequirementsByCategory, LoadingPage } from '@lama/app-components';
import { compact } from 'lodash-es';
import { ApplicationContext } from '../Application/ApplicationContext';
import { DocumentList } from './DocumentList';
import type { DocumentGroup, GroupDocumentListProps } from './types';
import { useDocumentGroupsByTopic } from './hooks/useDocumentGroupsByTopic';

export const TopicDocumentList: FC<GroupDocumentListProps> = React.memo(({ documents, allRequirements }) => {
  const { application } = useContext(ApplicationContext);
  const { groups: topicGroups, loading } = useDocumentGroupsByTopic(documents, allRequirements);

  const documentGroups: DocumentGroup[] = useMemo(() => {
    if (!topicGroups.length) {
      return [];
    }

    const requirementByCategory = getRequirementsByCategory(allRequirements, application);

    return topicGroups.map<DocumentGroup>((p) => ({
      ...p,
      documents: [],
      subGroups: compact(
        requirementByCategory.map((category) => {
          const requirementDocuments = p.documents?.filter((doc) => category.requirements.some((req) => req.id === doc?.requirement?.id));

          if (!requirementDocuments?.length || !category.requirements.length) {
            return null;
          }

          return {
            title: category.categoryLongName,
            documents: requirementDocuments,
            requirements: category.requirements,
          };
        }),
      ),
    }));
  }, [topicGroups, allRequirements, application]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!documents.length) {
    return null;
  }

  return <DocumentList groups={documentGroups} />;
});
