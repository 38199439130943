import React from 'react';
import type { FC } from 'react';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { UseOfFundsSummaryTable } from '../../../../shared/UseOfFunds/UseOfFundsSummaryTable';

export const GrasshopperUseOfFundsSummarySegment: FC = () => (
  <SegmentContainer title={'Purpose'} bordered={false}>
    <UseOfFundsSummaryTable />
  </SegmentContainer>
);
