import { getAbbreviatedUserName } from '@lama/data-formatters';
import { businessName } from '@lama/selectors';
import type { RequirementProperty } from '@lama/contracts';
import type { BusinessApiModel, RelatedPersonApiModel } from '@lama/business-service-client';
import type { Entity } from '@lama/common-types';

const getEntityName = (entity: Record<string, any>, entityType: Entity) => {
  if (entityType === 'person') {
    return getAbbreviatedUserName(entity as RelatedPersonApiModel);
  } else if (entityType === 'business') {
    return businessName(entity as BusinessApiModel);
  }
  return null;
};

export const getRowDisplayName = (property: RequirementProperty, showEntityNames: boolean, entity: Record<string, any>) => {
  if (!showEntityNames || !property.entityType) {
    return property.displayName;
  }

  const entityName = getEntityName(entity, property.entityType);

  return entityName ? `${property.displayName} (${entityName})` : property.displayName;
};
