import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import type { PaymentPeriod } from '@lama/contracts';
import { paymentPeriod as paymentPeriods } from '@lama/contracts';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import {
  FormikAutoSave,
  FormikDatePicker,
  FormikMoneyInputField,
  FormikPicker,
  PropertyFormikInput,
  displayToast,
} from '@lama/app-components';
import type { OpportunityUpdateApiModel } from '@lama/clients';
import { Flex, Grid, Text, greyPalette } from '@lama/design-system';
import { dateValidation } from '@lama/yup-validations';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PRIME_RATE } from '@lama/selectors';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { ConfirmLeaveWithUnsavedChanges } from '../../../../shared/components/ConfirmLeaveWithUnsavedChanges';

import { SaveFormButton } from '../SaveFormButton';
import { ApplicationContext } from '../../ApplicationContext';
import { getRecommendedRate } from './getRecommendedRate';
import { FIXED_RATE_TYPE, getRateType, PRIME_RATE_TYPE, rateTypes } from './termsUtils';
import { gcBranches } from './gcBranches';

const reviewYearOptions = Array.from({ length: 5 }, (_, i) => (new Date().getFullYear() - i).toString());

const validationSchema = yup.object().shape({
  disbursementDate: dateValidation,
  firstPaymentDate: dateValidation,
  submittedAt: dateValidation,
});

const onError = () => displayToast('There was a problem processing the request. Please try again.', 'error');

export const GCLoanTermsTermsSegment: FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);
  const { terms, id: opportunityId, branchCode, referenceYear } = opportunity;
  const { autoSaveEnabled } = useFlags();
  const { t } = useTranslation();

  const [suggestedRate, setSuggestedRate] = useState<number | undefined>();
  const { mutateAsync: updateOpportunity, isPending: updatingOpportunity } = useUpdateOpportunityMutation(opportunityId);

  const onMonthsBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value) {
        const months = Number.parseInt(e.target.value);
        const recommendedRate = getRecommendedRate(application, months);
        setSuggestedRate(recommendedRate);
      }
    },
    [application],
  );

  const initialValues = useMemo(
    () => ({
      branchId: branchCode ?? '',
      firstPaymentDate: terms?.firstPaymentDate ?? '',
      disbursementDate: terms?.disbursementDate ?? '',
      paymentPeriod: terms?.paymentPeriod ?? application.requestedPaymentPeriod ?? '',
      spread: terms?.rate?.spread?.toString() ?? application.requestedRate?.toString() ?? '',
      benchmarkRateType: getRateType(terms?.rate) ?? application.requestedRateType ?? '',
      termLength: terms?.termLength?.toString() ?? application.requestedTerm?.toString() ?? '',
      referenceYear: referenceYear.toString() ?? '',
      approvedLoanAmount: terms?.approvedLoanAmount?.toString() ?? '',
    }),
    [
      branchCode,
      terms?.firstPaymentDate,
      terms?.disbursementDate,
      terms?.paymentPeriod,
      terms?.rate,
      terms?.termLength,
      terms?.approvedLoanAmount,
      application.requestedPaymentPeriod,
      application.requestedRate,
      application.requestedRateType,
      application.requestedTerm,
      referenceYear,
    ],
  );

  const onFormikSubmit = useCallback(
    async (values: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      const newValues = {
        ...values,
        spread: values.benchmarkRateType === FIXED_RATE_TYPE && values.spread ? Number(values.spread) : null,
      };

      resetForm({ values: newValues });
      const opportunityUpdatePayload: OpportunityUpdateApiModel = {
        branchCode: newValues.branchId,
        terms: {
          firstPaymentDate: newValues.firstPaymentDate ? new Date(newValues.firstPaymentDate).toISOString() : undefined,
          disbursementDate: newValues.disbursementDate ? new Date(newValues.disbursementDate).toISOString() : undefined,
          paymentPeriod: newValues.paymentPeriod ? (newValues.paymentPeriod as PaymentPeriod) : undefined,
          termLength: newValues.termLength ? Number.parseInt(newValues.termLength) : undefined,
          rate: {
            isFixed: newValues.benchmarkRateType === FIXED_RATE_TYPE,
            benchmarkRateType: newValues.benchmarkRateType,
            benchmarkRate: newValues.benchmarkRateType === PRIME_RATE_TYPE ? PRIME_RATE : 0,
            spread: newValues.spread,
          },
          approvedLoanAmount: newValues.approvedLoanAmount ? Number(newValues.approvedLoanAmount) : null,
        },
        referenceYear: newValues.referenceYear ? Number.parseInt(newValues.referenceYear) : undefined,
      };

      await updateOpportunity(opportunityUpdatePayload, {
        onError,
      });
    },
    [updateOpportunity],
  );

  const rateHelperText = useMemo(() => (suggestedRate ? `Suggested Rate: ${suggestedRate}` : undefined), [suggestedRate]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onFormikSubmit}>
      {({ dirty, isValid, values, handleSubmit }) => (
        <ConfirmLeaveWithUnsavedChanges dirty={dirty}>
          <Flex flexDirection={'column'} gap={4}>
            <Flex flexDirection={'column'} gap={4}>
              <Text variant={'body1'} color={greyPalette[500]}>
                {'Terms'}
              </Text>
              <Grid columns={2}>
                <PropertyFormikInput name={'termLength'} label={'Term Length (Months)'} fullWidth type={'number'} onBlur={onMonthsBlur} />
                <FormikMoneyInputField name={'approvedLoanAmount'} label={'Proposed Loan Amount'} />
                <FormikPicker name={'benchmarkRateType'} label={'Rate Type'} values={rateTypes} fullWidth />
                {values.benchmarkRateType === FIXED_RATE_TYPE ? (
                  <PropertyFormikInput name={'spread'} label={'Rate'} fullWidth type={'number'} helperText={rateHelperText} />
                ) : null}
              </Grid>
            </Flex>
            <Flex flexDirection={'column'} gap={4}>
              <Text variant={'body1'} color={greyPalette[500]}>
                {'Details'}
              </Text>
              <Grid columns={2}>
                <FormikPicker name={'branchId'} label={'Branch ID'} values={gcBranches} fullWidth />
                <FormikDatePicker name={'disbursementDate'} label={'Disbursement Date'} fullWidth disableFuture={false} />
                <FormikPicker name={'paymentPeriod'} label={'Payment Period'} values={paymentPeriods} fullWidth />
                <FormikDatePicker name={'firstPaymentDate'} label={'First Payment Date'} fullWidth disableFuture={false} />
                <FormikPicker
                  name={'referenceYear'}
                  label={'Review Year'}
                  values={reviewYearOptions}
                  fullWidth
                  helperText={t('global.helperText.reviewYear')}
                />
              </Grid>
            </Flex>
            {autoSaveEnabled ? (
              <FormikAutoSave />
            ) : (
              <SaveFormButton loading={updatingOpportunity} submit={handleSubmit} disabled={!dirty || !isValid} />
            )}
          </Flex>
        </ConfirmLeaveWithUnsavedChanges>
      )}
    </Formik>
  );
};
