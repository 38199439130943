import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import * as selectors from '@lama/selectors';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsBorrowerDetailsSegment: FC = () => {
  const { application } = useContext(ApplicationContext);

  const borrowerNames = useMemo(
    () =>
      selectors
        .applicationBorrowingRelatedBusinessesSelector(application)
        .map((b) => selectors.businessName(b.business))
        .join(', '),
    [application],
  );

  const guarantorNames = useMemo(
    () =>
      selectors
        .applicationGuarantorsSelector(application)
        ?.map((guarantor) => selectors.personFullName(guarantor))
        .join(', '),
    [application],
  );

  return (
    <SegmentContainer title={'Borrowing Structure'}>
      <Grid columns={2}>
        <KeyValuePair name={'Relationship'} value={borrowerNames} />
        <KeyValuePair name={'Guarantor(s)'} value={guarantorNames} />
        <KeyValuePair name={'Facilities Amount'} value={application.requestedAmount} type={'currency'} />
      </Grid>
    </SegmentContainer>
  );
};
