import type { FC } from 'react';
import React, { useCallback, useMemo, useContext } from 'react';
import { IconButton } from '@mui/material';
import type { UnderwritingNote, UnderwritingNoteType } from '@lama/contracts';
import type { User } from '@lama/user-service-client';
import { Bookmark, BookmarkAdded, BookmarkRemove, Chat } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import type { InputValue } from '@lama/design-system';
import { Flex, amberPalette, greyPalette, redPalette, RichInput, Text } from '@lama/design-system';
import { displayToast } from '@lama/app-components';
import { useLocation } from 'react-router-dom';
import { ApplicationContext } from '../../../../ApplicationContext';
import { applicationServiceClient } from '../../../../../../shared/clients/applicationServiceClient';
import { useOpportunityRequirementsQuery } from '../../../../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';

import { useUserSuggestions } from '../../../../../../shared/components/Comments/hooks/useUserSuggestions';
import { getNoteRelatedItems } from '../../../../../../shared/utils/getNoteRelatedItems';
import { useUpdateNoteMutation } from '../../../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import { NoteTitle } from './NoteTitle';
import { ChangeNoteTypeButton } from './ChangeNoteTypeButton';

interface ChangeNoteTypeProps {
  changeNoteTypeCTA: string;
  noteTypeTarget: UnderwritingNoteType;
  confirmMessage?: string;
}

interface NoteCardProps {
  note: UnderwritingNote;
  user?: User;
  deleteEnabled?: boolean;
  changeNoteTypeButtonsProps?: ChangeNoteTypeProps[];
}

const noteTypeToIcon: Partial<Record<UnderwritingNoteType, React.ReactNode>> = {
  flag: (
    <Flex borderRadius={'2px'} backgroundColor={redPalette[50]} p={1}>
      <Bookmark sx={{ color: redPalette[400], width: '18px', height: '18px' }} />
    </Flex>
  ),
  exception: (
    <Flex borderRadius={'2px'} p={1} backgroundColor={amberPalette[50]}>
      <BookmarkAdded sx={{ color: amberPalette[700], width: '18px', height: '18px' }} />
    </Flex>
  ),
  cleared: (
    <Flex borderRadius={'2px'} p={1} backgroundColor={greyPalette[100]}>
      <BookmarkRemove sx={{ color: greyPalette[500], width: '18px', height: '18px' }} />
    </Flex>
  ),
  general: (
    <Flex borderRadius={'2px'} backgroundColor={greyPalette[200]} p={1}>
      <Chat sx={{ color: greyPalette[500], width: '18px', height: '18px' }} />
    </Flex>
  ),
};

export const NoteCard: FC<NoteCardProps> = ({ note, user, deleteEnabled = true, changeNoteTypeButtonsProps }) => {
  const { product, opportunity } = useContext(ApplicationContext);
  const currentUser = useContext(UserDetailsContext);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { data: opportunityRequirements } = useOpportunityRequirementsQuery(opportunity.id);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunity.id, currentUser?.userId);
  const userSuggestions = useUserSuggestions();
  const location = useLocation();
  const { tabName, sectionName } = useMemo(
    () => getNoteRelatedItems(note, opportunityRequirements ?? [], product),
    [note, opportunityRequirements, product],
  );

  const clearedFlag = useMemo(() => note.type === 'cleared', [note.type]);

  const { mutateAsync: submitNoteTextChange } = useMutation({
    mutationFn: async (change?: InputValue) => {
      if (change?.value) {
        return;
      }

      const noteUrl = `${location.pathname}${location.search}`;

      await updateNote({ noteId: note.id, notePayload: { text: change?.value, mentionedUserIds: change?.mentionedIds, url: noteUrl } });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunity.id] });
      await queryClient.invalidateQueries({ queryKey: ['opportunityEvaluation', opportunity.id] });
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });

  const { mutateAsync: submitNoteClearReasonChange } = useMutation({
    mutationFn: async (clearReason: InputValue) => {
      await updateNote({ noteId: note.id, notePayload: { clearReason: clearReason.value } });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunity.id] });
      await queryClient.invalidateQueries({ queryKey: ['opportunityEvaluation', opportunity.id] });
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });

  const { mutate: deleteNote } = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.deleteNote(opportunity.id, note.id, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunity.id] });
      await queryClient.invalidateQueries({ queryKey: ['opportunityEvaluation', opportunity.id] });
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });

  const onTextSubmit = useCallback(
    async (change: InputValue) => {
      await submitNoteTextChange(change);
    },
    [submitNoteTextChange],
  );

  const onClearReasonSubmit = useCallback(
    async (text: InputValue) => {
      await submitNoteClearReasonChange(text);
    },
    [submitNoteClearReasonChange],
  );

  const onDeleteException = useCallback(() => {
    deleteNote();
  }, [deleteNote]);

  const NoteIcon = useMemo(() => noteTypeToIcon[note.type], [note.type]);

  return (
    <Flex
      flexDirection={'column'}
      gap={4}
      px={4}
      py={4}
      backgroundColor={'white'}
      border={`1px solid ${greyPalette[300]}`}
      borderRadius={'4px'}
    >
      <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
        <Flex gap={4} flexDirection={'row'} alignItems={'center'} maxWidth={'85%'}>
          {NoteIcon}
          <NoteTitle tabName={tabName} sectionName={sectionName} user={user} createdAt={note.createdAt} type={note.type} />
        </Flex>
        <Flex gap={2} alignItems={'center'}>
          {changeNoteTypeButtonsProps?.map((changeNoteTypeButtonProps) => (
            <ChangeNoteTypeButton
              key={changeNoteTypeButtonProps.noteTypeTarget}
              opportunityId={opportunity.id}
              noteId={note.id}
              {...changeNoteTypeButtonProps}
            />
          ))}
          {deleteEnabled ? (
            <IconButton aria-label={'delete'} onClick={onDeleteException}>
              <DeleteIcon />
            </IconButton>
          ) : null}
        </Flex>
      </Flex>
      {note.text?.length ? (
        <RichInput
          suggestions={userSuggestions}
          value={note.text}
          onSubmit={onTextSubmit}
          enableMentions={!clearedFlag}
          allowEditing={!clearedFlag}
        />
      ) : null}
      {clearedFlag ? (
        <Flex flexDirection={'column'}>
          <Text variant={'body2'} color={'secondary'}>
            {'Reason for clearing'}
          </Text>
          <RichInput value={note.clearReason} onSubmit={onClearReasonSubmit} />
        </Flex>
      ) : null}
    </Flex>
  );
};
