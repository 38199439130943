/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import type { DocumentBoxProps as InnerDocumentBoxProps, OnMetadataUpdateProps } from '@lama/app-components';
import { DocumentBox as DocumentBoxInner, useConfirmModal } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import type { DataNode } from '@lama/design-system';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateRequirement } from '../../hooks/react-query/requirement/useUpdateRequirement';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useUpdateDocumentMutation } from '../../../components/Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useUpdateDocumentMutation';
import { useDeleteDocumentQuery } from './hooks/useDeleteDocumentQuery';
import { useUploadDocumentsMutation } from './hooks/useUploadDocumentsQuery';

interface DocumentBoxProps extends Partial<InnerDocumentBoxProps> {
  description?: string;
  topic?: string;
  document?: DocumentWithIssues;
  requirement?: EvaluatedOpportunityRequirement;
  entityId?: string;
  entityType?: Entity;
  applicationId?: string;
  sharedRequirementId?: string;
  requirementKey?: string | undefined;
  moveToTreeData?: DataNode[];
  onClickMoveTo?: (moveToNode: DataNode) => Promise<void>;
  onUploadCompleted?: (documentId: string) => void;
}

export interface MoveToDataNode {
  requirement?: EvaluatedOpportunityRequirement;
  documentId?: string;
  topic?: string;
}

export const DocumentBox: React.FC<DocumentBoxProps> = ({
  requirement: { id: requirementId, opportunityId, sources, exportConfiguration } = {},
  topic: topicProp,
  entityId,
  entityType,
  document,
  applicationId,
  dismissed,
  sharedRequirementId,
  requirementKey,
  description,
  moveToTreeData,
  onUploadCompleted,
}) => {
  const { firstName, lastName } = useContext(UserDetailsContext);
  const { mutateAsync: uploadDocuments } = useUploadDocumentsMutation();
  const { mutateAsync: deleteDocument } = useDeleteDocumentQuery();
  const { mutateAsync: updateRequirement } = useUpdateRequirement(opportunityId ?? '', requirementId ?? '');
  const { mutateAsync: updateDocument } = useUpdateDocumentMutation({ applicationId });
  const { confirm } = useConfirmModal();

  const onClickMoveTo = useCallback(
    async (moveToNode: DataNode<MoveToDataNode>) => {
      const confirmed = await confirm({
        title: 'Move Document',
        cancelText: 'Cancel',
        confirmText: 'Move',
        message: `Are you sure you want to move this document to ${moveToNode.title?.toString()}?`,
      });

      if (!confirmed) {
        return;
      }

      await updateDocument({
        documentId: document?.id ?? '',
        payload: {
          description: moveToNode.requirement?.name,
          topic: moveToNode.topic,
          relatedEntityId: moveToNode.requirement?.entityId,
          relatedEntityType: moveToNode.requirement?.entityType,
          requirementId: moveToNode.requirement?.id,
          sharedRequirementId: moveToNode.requirement?.referenceRequirementId,
          requirementKey: moveToNode.requirement?.key,
        },
      });
    },
    [confirm, document?.id, updateDocument],
  );

  const innerDescription = useMemo(() => (description || document?.description) ?? '', [description, document?.description]);
  const innerTopic = useMemo(() => (topicProp || document?.topic) ?? '', [topicProp, document?.topic]);

  const onUploadDocument = useCallback(
    async (file: File) => {
      if (!applicationId || !entityId || !entityType || !innerTopic || !innerDescription) {
        throw new Error(' required when adding a document, contact support');
      }

      const documentId = uuidv4();

      await uploadDocuments([
        {
          documentId,
          file,
          topic: innerTopic,
          entityId,
          entityType,
          description: innerDescription,
          requirementId,
          applicationId,
          sharedRequirementId,
          requirementKey,
          exportConfiguration,
        },
      ]);

      onUploadCompleted?.(documentId);
    },
    [
      applicationId,
      entityId,
      entityType,
      innerTopic,
      innerDescription,
      uploadDocuments,
      requirementId,
      sharedRequirementId,
      requirementKey,
      exportConfiguration,
      onUploadCompleted,
    ],
  );

  const [, onDocumentMetadataChange] = useAsyncFn(
    async (metadata: OnMetadataUpdateProps) => {
      const didDocumentDescriptionChange = document?.description !== metadata.description;

      const documentUpdatePayload = {
        ...(didDocumentDescriptionChange ? { description: metadata.description } : {}),
        ...(metadata.filename && document?.filename !== metadata.filename ? { filename: metadata.filename } : {}),
      };

      if (document && Object.keys(documentUpdatePayload).length) {
        await updateDocument({ documentId: document.id, payload: documentUpdatePayload });
      }

      if (didDocumentDescriptionChange) {
        const requirementUpdatePayload = {
          sources: {
            uploadFilesSource: sources?.uploadFilesSource?.map((s) => (s.name === description ? { ...s, name: metadata.description } : s)),
          },
        };

        await updateRequirement({ updateRequirementPayload: requirementUpdatePayload });
      }
    },
    [description, document, sources?.uploadFilesSource, updateDocument, updateRequirement],
  );

  const onDeleteDocument = useCallback(async () => {
    if (document) {
      const result = await confirm({
        title: 'Delete Document',
        message: 'Are you sure you want to delete this document?',
        cancelText: 'Cancel',
        confirmText: 'Delete',
        type: 'danger',
      });

      if (!result) {
        return;
      }

      await deleteDocument({ documentId: document?.id });
    }
  }, [deleteDocument, document, confirm]);

  const onDismissDocument = useCallback(async () => {
    if (!applicationId || !entityId) {
      throw new Error('required when dismissing a document, contact support');
    }

    const confirmed = await confirm({
      title: 'Dismiss Document',
      message: 'Are you sure this document is not needed for this application?',
    });

    if (!confirmed) {
      return;
    }

    await updateRequirement({
      updateRequirementPayload: {
        sources: {
          uploadFilesSource: sources?.uploadFilesSource?.map((s) =>
            s.name === description
              ? {
                  ...s,
                  dismissDataByEntity: {
                    ...s.dismissDataByEntity,
                    [entityId]: {
                      reason: 'Other',
                      description: `Dismissed by ${firstName && lastName ? `${firstName} ${lastName}` : 'lender'}`,
                    },
                  },
                }
              : s,
          ),
        },
      },
    });
  }, [applicationId, confirm, description, entityId, firstName, lastName, sources?.uploadFilesSource, updateRequirement]);

  const onUndismissDocument = useCallback(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        sources: {
          uploadFilesSource: sources?.uploadFilesSource?.map((s) =>
            s.name === description
              ? {
                  ...s,
                  dismissDataByEntity: {},
                }
              : s,
          ),
        },
      },
    });
  }, [description, sources?.uploadFilesSource, updateRequirement]);

  return (
    <DocumentBoxInner
      description={innerDescription}
      document={document}
      dismissed={dismissed}
      onMetadataUpdate={onDocumentMetadataChange}
      onDocumentUpload={onUploadDocument}
      onDocumentRemoved={onDeleteDocument}
      onDismissSource={onDismissDocument}
      onUndismissSource={onUndismissDocument}
      moveToTreeData={moveToTreeData}
      onMoveToClick={onClickMoveTo}
      dismissible
    />
  );
};
