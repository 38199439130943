/* eslint-disable @typescript-eslint/naming-convention */
import type { ApplicationStatus } from '@lama/contracts';
import type { PipelineItem } from '@lama/pipeline-items-service-client';
import { isAlertRelevantNow } from './hooks/isAlertRelevantNow';

const workflowsCategoriesToStatuses: Record<string, ApplicationStatus[]> = {
  Leads: ['Draft'],
  Qualified: ['OnboardingCompleted', 'InReview'],
  'Pending Decision': ['PendingApproval', 'PendingLost'],
  Closing: ['Closing'],
  Closed: ['Closed'],
  Lost: ['Rejected', 'ExpresslyWithdrawn', 'ApprovedNotAccepted', 'WithdrawalOfApprovedApplication', 'Cancelled'],
};

export const getPipelineCategoriesForItem = (pipelineItem: PipelineItem): string[] => {
  const categories = Object.entries(workflowsCategoriesToStatuses)
    .filter(([, s]) => s.includes(pipelineItem.applicationStatus))
    .map(([c]) => c);

  const relevantAlerts = pipelineItem.alerts?.filter(isAlertRelevantNow);

  if (relevantAlerts?.length) {
    categories.push('alerts');
  }

  return categories;
};
