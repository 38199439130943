import { Flex, grayPalette } from '@lama/design-system';
import type { FC } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApplicationContext } from '../ApplicationContext';
import { getRelevantSections } from '../shared/sectionUtils';
import { UnderwritingSideNav } from './SideNav/UnderwritingSideNav';
import { UnderwritingSectionContent } from './UnderwritingSectionContent';

export const UnderwritingPage: FC = () => {
  const { product, opportunity } = useContext(ApplicationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const section = searchParams.get('section');

  const multipliedUnderwritingConfiguration = useMemo(
    () =>
      product.underwritingConfiguration?.map((group) => ({
        ...group,
        sections: getRelevantSections(group.sections, opportunity),
      })) ?? [],
    [opportunity, product.underwritingConfiguration],
  );

  const currentUnderwritingSection = useMemo(
    () => multipliedUnderwritingConfiguration.flatMap(({ sections }) => sections).find((s) => s.id === section),
    [multipliedUnderwritingConfiguration, section],
  );

  useEffect(() => {
    if (!currentUnderwritingSection) {
      const firstSection = multipliedUnderwritingConfiguration[0]?.sections[0]?.id;

      if (firstSection) {
        setSearchParams({ section: firstSection }, { replace: true });
      }
    }
  }, [currentUnderwritingSection, multipliedUnderwritingConfiguration, setSearchParams]);

  return (
    <Flex flex={1} border={`1px solid ${grayPalette[300]}`} borderRadius={1}>
      <UnderwritingSideNav underwritingCategories={multipliedUnderwritingConfiguration} />
      <UnderwritingSectionContent section={currentUnderwritingSection} />
    </Flex>
  );
};
