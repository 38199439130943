import React from 'react';
import type { FC } from 'react';
import { TransactionEstimateItem, DebtItem, FeeItem } from '@lama/app-components';
import { SpreadingDialogContent } from '../../../Spreading/SpreadingDialog/SpreadingContent';
import { BankRequirement } from './BankRequirement/BankRequirement';
import { GenericRequirementForm } from './RequirementScreens/GenericRequirementForm';
import { UseOfFundsBreakdownItem } from './RequirementScreens/ListItems/UseOfFundsBreakdownItem';
import type { RequirementScreenProps } from './RequirementScreens/types';
import { DocumentRequirementScreen } from './RequirementScreens/DocumentRequirementScreen';
import { UseOfFundsScreen } from './RequirementScreens/UseOfFundsScreen';
import { CollateralItem } from './RequirementScreens/ListItems/CollateralItem';
import { PrincipalsScreen } from './RequirementScreens/Principals/PrincipalsScreen';
import { RevenueScreen } from './RequirementScreens/financials/RevenueScreen';
import { RelatedCompaniesScreen } from './RequirementScreens/RelatedCompanies/RelatedCompaniesScreen';
import { BusinessDetailsForm } from './RequirementScreens/BusinessDetailsForm';
import { StreamlinedRevenueScreen } from './RequirementScreens/financials/StreamlinedRevenueScreen';
import { CollateralEmptyState } from './RequirementScreens/listEmptyStates/CollateralEmptyState';
import { DebtEmptyState } from './RequirementScreens/listEmptyStates/DebtEmptyState';
import { UseOfFundsBreakdownEmptyState } from './RequirementScreens/listEmptyStates/UseOfFundsBreakdownEmptyState';
import { PersonalFinancials } from './RequirementScreens/PersonalFinancials/PersonalFinancialsScreen';
import { CreditReportRequirementScreen } from './RequirementScreens/creditReport/CreditReportRequirementScreen';
import { UseOfFundsBreakdownIndication } from './RequirementScreens/customIndications/UseOfFundsBreakdownIndication';
import { LenderSubmitScreen } from './RequirementScreens/LenderSubmitScreen';
import { AffiliateVerificationScreen } from './RequirementScreens/financials/AffiliateVerificationScreen';
import { RelationsList } from './RequirementScreens/RelationsList/RelationsList';
import { PropertiesList } from './RequirementScreens/RelationsList/PropertiesList';
import { GC14FamilyPersonalFinancials } from './RequirementScreens/PersonalFinancials/GC14FamilyPersonalFinancials';
import { GatecityLoanDetailsRequirementScreen } from './RequirementScreens/GatecityLoanDetailsRequirementScreen';

export const requirementScreenToComponent: Record<string, FC<RequirementScreenProps>> = {
  businessDetails: (props: any) => <BusinessDetailsForm {...props} />,
  tbdBusinessDetails: (props: any) => <GenericRequirementForm {...props} />,
  personInformation: (props: any) => <GenericRequirementForm {...props} />,
  personVerification: (props: any) => <GenericRequirementForm {...props} />,
  documentRequirement: (props: any) => <DocumentRequirementScreen {...props} />,
  bankStatements: (props: any) => <BankRequirement {...props} />,
  financialStatements: (props: any) => <DocumentRequirementScreen {...props} />,
  useOfFunds: (props: any) => <UseOfFundsScreen {...props} />,
  idVerification: (props: any) => <GenericRequirementForm {...props} />,
  revenue: (props: any) => <RevenueScreen {...props} />,
  steamlinedRevenue: (props: any) => <StreamlinedRevenueScreen {...props} />,
  financials: (props: any) => <SpreadingDialogContent {...props} />,
  compliance: (props: any) => <GenericRequirementForm {...props} />,
  useOfFundsBreakdown: (props: any) => <GenericRequirementForm {...props} />,
  debts: (props: any) => <GenericRequirementForm {...props} />,
  collateral: (props: any) => <GenericRequirementForm {...props} />,
  requestedAmount: (props: any) => <GenericRequirementForm {...props} />,
  principals: (props: any) => <PrincipalsScreen {...props} />,
  relatedCompanies: (props: any) => <RelatedCompaniesScreen {...props} />,
  owners: (props: any) => <PrincipalsScreen {...props} />,
  loanDetails: (props: any) => <GenericRequirementForm {...props} />,
  personalFinancials: (props: any) => <PersonalFinancials {...props} />,
  businessDescription: (props: any) => <GenericRequirementForm {...props} />,
  loanDescription: (props: any) => <GenericRequirementForm {...props} />,
  businessVerification: (props: any) => <GenericRequirementForm {...props} />,
  financialReferences: (props: any) => <GenericRequirementForm {...props} />,
  otherIncomes: (props: any) => <GenericRequirementForm {...props} />,
  lenderSubmit: (props: any) => <LenderSubmitScreen {...props} />,
  genericForm: (props: any) => <GenericRequirementForm {...props} />,
  creditReport: (props: any) => <CreditReportRequirementScreen {...props} />,
  affiliateVerification: (props: any) => <AffiliateVerificationScreen {...props} />,
  businessAcquisitionList: (props: any) => (
    <RelationsList
      {...props}
      relation={'seller'}
      searchExistingCustomersAllowed
      allowedEntityTypes={['business']}
      title={'Acquired Businesses'}
    />
  ),
  borrowers: (props: any) => (
    <RelationsList searchExistingCustomersAllowed {...props} relation={'borrower'} allowedEntityTypes={['business', 'person']} />
  ),
  properties: (props: any) => <PropertiesList {...props} />,
  guests: (props: any) => (
    <RelationsList
      searchExistingCustomersAllowed
      {...props}
      relation={'guest'}
      allowedEntityTypes={['person']}
      title={'Guests'}
      showMarkNoEntitiesCheckbox
    />
  ),
  additionalGuarantors: (props: any) => (
    <RelationsList
      {...props}
      relation={'explicitGuarantor'}
      searchExistingCustomersAllowed
      allowedEntityTypes={['person', 'business']}
      title={'Additional Guarantors'}
      showMarkNoEntitiesCheckbox
    />
  ),
  gc14FamilyPersonalFinancials: (props: any) => <GC14FamilyPersonalFinancials {...props} />,
  gc14FamilyLoanDetails: (props: any) => <GatecityLoanDetailsRequirementScreen {...props} />,
  transactionEstimates: (props: any) => <GenericRequirementForm {...props} />,
  fees: (props: any) => <GenericRequirementForm {...props} />,
};

export interface RequirementListItemProps {
  item: any;
}

export const requirementToListItemComponent: Record<string, FC<RequirementListItemProps>> = {
  useOfFundsBreakdown: UseOfFundsBreakdownItem,
  debt: DebtItem,
  collateral: CollateralItem,
  transactionEstimates: TransactionEstimateItem,
  fees: FeeItem,
};

export const listRequirementToEmptyStateImage: Record<string, FC> = {
  collateral: () => <CollateralEmptyState />,
  debt: () => <DebtEmptyState />,
  useOfFundsBreakdown: () => <UseOfFundsBreakdownEmptyState />,
  transactionEstimates: () => <UseOfFundsBreakdownEmptyState />,
  fees: () => <UseOfFundsBreakdownEmptyState />,
};

export const requirementToIndicationComponent: Record<string, FC<RequirementScreenProps>> = {
  useOfFundsBreakdown: UseOfFundsBreakdownIndication,
};
