import { compact, groupBy } from 'lodash-es';
import type { OpportunityRequirement } from '@lama/contracts';
import { useContext, useMemo } from 'react';
import type { DocumentDetails, DocumentGroup } from '../types';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { useOpportunityClosingTasksQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';

export const useDocumentGroupsByTopic = (documents: DocumentDetails[], allRequirements: OpportunityRequirement[]) => {
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  const { data: closingTasks, isPending: loadingTasks } = useOpportunityClosingTasksQuery(opportunityId);

  return useMemo(() => {
    const documentsWithRequirement = documents.filter((d) => d.requirement?.id);
    const documentsByRequirementId = groupBy(documentsWithRequirement, (d) => d.requirement?.referenceRequirementId);

    const requirementGroups: DocumentGroup[] = Object.entries(documentsByRequirementId)
      .filter(([requirementId]) => requirementId)
      .map(([referenceRequirement, docs]) => {
        const relevantRequirements = allRequirements.filter((r) => r.referenceRequirementId === referenceRequirement);

        if (!relevantRequirements.length) {
          return {
            title: 'Unknown Requirement',
            documents: docs,
          };
        }

        return {
          title: relevantRequirements.at(0)?.name,
          documents: docs,
          requirements: relevantRequirements,
        } as DocumentGroup;
      });

    const documentsWithClosingTask = documents.filter((d) => closingTasks?.some((task) => task.documentIds?.includes(d.id)));

    const closingTaskSubGroup: DocumentGroup = {
      title: 'Closing Tasks',
      documents: documentsWithClosingTask,
    };

    return {
      loading: loadingTasks,
      groups: compact([...requirementGroups, closingTaskSubGroup?.documents?.length ? closingTaskSubGroup : null]),
    };
  }, [documents, allRequirements, closingTasks, loadingTasks]);
};
