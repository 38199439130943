import type { DataNode } from '@lama/design-system';
import { Flex, Collapse, Text, Spinner } from '@lama/design-system';
import { isNil } from 'lodash-es';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { useAsyncFn, useToggle } from 'react-use';
import { IconButton } from '@mui/material';
import { Tooltip, DownloadIcon } from '@lama/app-components';
import ExpandButton from '../../shared/components/ExpandButton';
import { ApplicationContext } from '../Application/ApplicationContext';
import { DocumentBox } from '../../shared/components/DocumentBox/DocumentBox';
import { DocumentListSubGroup } from './DocumentListSubGroup';
import type { DocumentGroup as DocumentGroupType } from './types';
import { useDownloadDocumentGroup } from './hooks/useDownloadDocumentGroup';

interface DocumentGroupProps {
  group: DocumentGroupType;
  moveToTreeData: DataNode[];
}
export const DocumentGroup: FC<DocumentGroupProps> = ({ group, moveToTreeData }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const [listExpanded, toggleListExpanded] = useToggle(true);

  const downloadDocumentGroup = useDownloadDocumentGroup(group);

  const groupKey = useMemo(() => group.title + group.subtitle || group.documents?.at(0)?.id, [group]);

  const isFlatDocumentList = useMemo(() => isNil(group.subGroups) || (group.subGroups.length === 0 && group.documents?.length), [group]);

  const [{ loading: downloadingDocuments }, onClickDownloadAll] = useAsyncFn(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      await downloadDocumentGroup();
    },
    [downloadDocumentGroup],
  );

  if (!group?.subGroups?.some((subgroup) => subgroup.documents?.length) && !group.documents?.length) {
    return null;
  }

  return (
    <Flex key={groupKey} flexDirection={'column'} gap={6}>
      <Flex gap={2} alignItems={'center'} onClick={toggleListExpanded}>
        <ExpandButton expand={listExpanded} sx={{ padding: 0 }} disableRipple />
        <Flex gap={2} alignItems={'center'}>
          <Flex gap={2} alignItems={'center'} justifyContent={'center'}>
            <Text variant={'body1'}>{group.title}</Text>
            <Tooltip title={'Download all'} placement={'top'}>
              {!downloadingDocuments ? (
                <IconButton
                  sx={{ width: '30px' }}
                  onClick={onClickDownloadAll}
                  aria-label={'Download'}
                  role={'button'}
                  disabled={downloadingDocuments}
                >
                  <DownloadIcon size={'14px'} />
                </IconButton>
              ) : (
                <Spinner size={'14px'} />
              )}
            </Tooltip>
          </Flex>
        </Flex>
        {group.icon && group.subtitle ? (
          <Flex gap={2} alignItems={'center'}>
            {group.icon}
            <Text variant={'body3'} color={'secondary'} ellipsis data-sentry-unmask>
              {group.subtitle}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      <Collapse expanded={listExpanded}>
        <Flex flexDirection={'column'} gap={6}>
          {isFlatDocumentList ? (
            <Flex flexDirection={'column'} gap={3}>
              {group.documents?.map(({ document, requirement, ...details }) => (
                <DocumentBox
                  applicationId={applicationId}
                  key={document?.id || details.fileName + details.name}
                  document={document}
                  description={details.name}
                  dismissed={details.dismissed}
                  entityId={requirement?.entityId}
                  entityType={requirement?.entityType}
                  requirement={requirement}
                  topic={details.topic}
                  sharedRequirementId={requirement?.referenceRequirementId}
                  moveToTreeData={moveToTreeData}
                />
              ))}
            </Flex>
          ) : (
            // eslint-disable-next-line react/jsx-key
            group.subGroups?.map((subgroup) => <DocumentListSubGroup subGroup={subgroup} moveToTreeData={moveToTreeData} />)
          )}
        </Flex>
      </Collapse>
    </Flex>
  );
};
