import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { annualRateSelector, monthlyPaymentSelector, proposedLoanAmount as proposedLoanAmountSelector } from '@lama/selectors';
import { isNil } from 'lodash-es';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { formatValue } from '@lama/data-formatters';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsDetailsSegment: FC<{ showSbaData?: boolean }> = ({ showSbaData = true }) => {
  const { application, opportunity, product } = useContext(ApplicationContext);

  const proposedLoanAmount = useMemo(() => proposedLoanAmountSelector(application), [application]);

  const proposedLoanMonthlyPayment = useMemo(() => {
    const annualRate = annualRateSelector(application);

    if (!annualRate || !opportunity.terms?.termLength || isNil(proposedLoanAmount)) {
      return null;
    }

    return monthlyPaymentSelector(annualRate, opportunity.terms.termLength, proposedLoanAmount);
  }, [application, opportunity.terms?.termLength, proposedLoanAmount]);

  const exposureNetofSBAGuaranty = useMemo(() => {
    const sbaGuarantyPercentage = opportunity.underwriting?.additionalData?.sbaGuarantyPercentage;

    if (isNil(proposedLoanAmount) || isNil(sbaGuarantyPercentage)) {
      return null;
    }

    return ((100 - sbaGuarantyPercentage) / 100) * proposedLoanAmount;
  }, [opportunity.underwriting?.additionalData?.sbaGuarantyPercentage, proposedLoanAmount]);

  return (
    <SegmentContainer title={'Details'}>
      <Grid columns={2}>
        <KeyValuePair name={'Application Date'} value={formatValue(application.submittedAt, 'date')} />
        <KeyValuePair name={'Proposed Loan Amount'} value={proposedLoanAmount} type={'currency'} />
        <KeyValuePair name={'Requested Amount'} value={application.requestedAmount} type={'currency'} />
        <KeyValuePair name={'Monthly Payment'} value={proposedLoanMonthlyPayment} type={'currency'} />
        {showSbaData ? <KeyValuePair name={'Exposure Net of SBA Guaranty'} value={exposureNetofSBAGuaranty} type={'currency'} /> : null}
        <KeyValuePair name={'Product'} value={product.prettyName} />
        <KeyValuePair name={'Request Type'} value={opportunity.loanType} />
        <KeyValuePair name={'Disbursement Method'} value={application.disbursementMethod} />
        <KeyValuePair name={'Reg O'} value={application.regO} type={'boolean'} />
      </Grid>
    </SegmentContainer>
  );
};
