import type { DataNode } from '@lama/design-system';
import { useMemo } from 'react';
import { compact } from 'lodash-es';
import type { DocumentGroup } from '../types';
import type { MoveToDataNode } from '../../../shared/components/DocumentBox/DocumentBox';

export const useDocumentsMoveToTree = (documentGroups: DocumentGroup[]): DataNode<MoveToDataNode>[] =>
  useMemo(
    () =>
      compact(
        documentGroups.map((group) => {
          const filteredSubGroups = compact(
            group.subGroups?.map((subGroup) => {
              const placeholdersWithDocument = subGroup.documents?.filter((document) => !document.document);

              if (placeholdersWithDocument && placeholdersWithDocument.length > 0) {
                return {
                  key: subGroup.title,
                  title: subGroup.title,
                  children: placeholdersWithDocument.map((document) => ({
                    key: document.id,
                    title: document.name,
                    requirement: document.requirement,
                    topic: document.topic,
                  })) as DataNode<MoveToDataNode>[],
                };
              }
              return null;
            }),
          );

          if (filteredSubGroups && filteredSubGroups.length > 0) {
            return {
              key: group.title,
              title: group.title,
              children: filteredSubGroups,
            };
          }
          return null;
        }),
      ),
    [documentGroups],
  );
