import React, { Suspense, useContext } from 'react';
import type { FC } from 'react';
import { LoadingPage } from '@lama/app-components';
import type { GetScrollRestorationKeyFunction } from 'react-router-dom';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { ErrorBoundary } from '@sentry/react';
import { Flex } from '@lama/design-system';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLaunchDarklyIdentify } from '../hooks/useLaunchDarklyIdentify';
import { UserDetailsContext } from '../context/UserDetailsContext';
import { useLogin } from '../hooks/useLogin';
import { useSentryIdentification } from '../hooks/useSentryIdentification';
import { useMixpanelIdentification } from '../hooks/useMixpanelIdentification';
import { useGetPartnerQuery } from '../hooks/react-query/partner/useGetPartnerQuery';
import { ApplicationStatusDisplayNameMappingProvider } from '../context/ApplicationStatusDisplayNameMappingContext';
import { ApplicationSearch } from '../../components/Application/ApplicationSearch/ApplicationSearch';
import { SideNav } from './SideNav/SideNav';
import { ErrorPage } from './ErrorPage/ErrorPage';
import '../../styling/styling.css';

const scrollRestorationPaths = new Set(['/deals']);
const getScrollKey: GetScrollRestorationKeyFunction = (location) =>
  scrollRestorationPaths.has(location.pathname) ? `${location.pathname}${location.search}` : location.key;

export const Layout: FC = () => {
  const user = useContext(UserDetailsContext);
  const { searchApplication } = useFlags();
  const { partner: userPartner } = user;
  const { data: partner, isPending: fetchingPartner, error } = useGetPartnerQuery(userPartner);
  useLogin();
  const { t } = useTranslation();

  const { identified } = useLaunchDarklyIdentify(user);

  useSentryIdentification();
  useMixpanelIdentification();

  if (isEmpty(user) || fetchingPartner || !identified) {
    return (
      <Flex height={'100vh'} width={'100vw'} alignItems={'center'} justifyContent={'center'}>
        {<LoadingPage />}
      </Flex>
    );
  }

  if (!partner || (axios.isAxiosError(error) && (error.response?.status === 403 || error.response?.status === 404))) {
    return <ErrorPage title={t('global.error.forbidden')} />;
  }

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <ApplicationStatusDisplayNameMappingProvider partner={partner}>
        <Flex height={'100vh'} width={'100%'} overflow={'hidden'}>
          <SideNav />
          <Flex flexDirection={'column'} flex={1} width={'100%'} overflow={'auto'}>
            <Suspense fallback={<LoadingPage />}>
              <Outlet />
            </Suspense>
          </Flex>
          {searchApplication ? <ApplicationSearch /> : null}
        </Flex>
        <ScrollRestoration getKey={getScrollKey} />
      </ApplicationStatusDisplayNameMappingProvider>
    </ErrorBoundary>
  );
};
