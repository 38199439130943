import type { FC } from 'react';
import React, { forwardRef, useContext, useMemo } from 'react';
import './creditMemoCover.css';
import { Flex } from '@lama/design-system';
import type { MemoSection } from '@lama/contracts';
import { applicationBorrowingRelatedBusinessesSelector } from '@lama/selectors';
import { evaluateMatcher } from '@lama/conditions';
import { isNil } from 'lodash-es';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { useGetPartnerQuery } from '../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { useGetGooglePlacePhotoUrl } from '../../../shared/hooks/useGetGooglePlacePhotoUrl';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { SectionTitle } from '../../Application/shared/SectionTitle/SectionTitle';
import { SectionDecisioning } from '../../Application/shared/SectionDecisioning';
import { CreditMemoSectionComponent } from '../../Application/CreditMemo/Sections/components/CreditMemoContent';
import { CreditMemoCoverPage } from './CreditMemoCoverPage';

interface CreditMemoExportProps {
  businessName: string;
}

const CreditMemoExportContent: FC<{ section: MemoSection }> = ({ section }) => (
  <Flex flexDirection={'column'} key={section.id} className={'sectionContainer'} gap={6}>
    <Flex flexDirection={'column'} gap={4}>
      <SectionTitle sectionId={section.id} sectionType={'creditMemoSection'} title={section.prettyName} />
      {section.hideUnderwritingActions ? null : <SectionDecisioning sectionId={section.id} sectionType={'creditMemoSection'} />}
    </Flex>
    <CreditMemoSectionComponent section={section} printMode />
  </Flex>
);

export const CreditMemoExport = forwardRef<HTMLDivElement, CreditMemoExportProps>(({ businessName }, ref) => {
  const { application, product, opportunity } = useContext(ApplicationContext);
  const { partner: partnerId } = useContext(UserDetailsContext);
  const relatedBusinesses = applicationBorrowingRelatedBusinessesSelector(application);
  const businessPhotoUrl = useGetGooglePlacePhotoUrl(relatedBusinesses?.[0]?.business?.googlePlaceId);
  const { data: partner } = useGetPartnerQuery(partnerId);

  const creditMemoSections = useMemo(
    () =>
      product.creditMemoConfiguration?.sections.filter(({ enabledMatcher }) => {
        if (enabledMatcher) {
          const evaluationResult = evaluateMatcher({
            matcher: enabledMatcher,
            application,
            opportunity: { ...opportunity, applicationId: opportunity.application.id },
          });

          const isSectionEnabled = evaluationResult.result && !isNil(evaluationResult.extractedValue);

          if (!isSectionEnabled) {
            return false;
          }
        }

        return true;
      }),
    [application, opportunity, product.creditMemoConfiguration?.sections],
  );

  if (!application || !partner) {
    return null;
  }

  return (
    <div ref={ref} className={'hiddenContainer'}>
      <CreditMemoCoverPage
        partner={partner}
        businessName={businessName}
        businessImage={businessPhotoUrl}
        loanAmount={application.requestedAmount ?? 0}
      />
      <Flex flexDirection={'column'} gap={3}>
        {creditMemoSections?.map((section) => (
          <CreditMemoExportContent key={section.key} section={section} />
        ))}
      </Flex>
    </div>
  );
});
