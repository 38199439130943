import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, greyPalette } from '@lama/design-system';
import { loanDisplayNameSelector } from '@lama/selectors';
import { saveAs } from 'file-saver';
import { LoadingPage } from '@lama/app-components';
import { compact } from 'lodash-es';
import { ApplicationContext } from '../../../ApplicationContext';
import { transformToLaserProPremiumFormat } from '../../../ActionsMenu/utils/laserProPremium';
import { useUsersByPartnerQuery } from '../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useLenderProfilesByPartnerQuery } from '../../../../../shared/hooks/react-query/lenderProfile/useLenderProfilesByPartnerQuery';
import { useGetPartnerQuery } from '../../../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import type { ClosingTaskComponentProps } from './closingTaskComponentProps';
import { ExportLine } from './shared/ExportLine';

export const LaserProPremiumExportClosingTask: FC<ClosingTaskComponentProps> = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const loanDisplayName = useMemo<string | null>(() => loanDisplayNameSelector(application), [application]);

  const { data: opportunityPartner, isPending: loadingPartner } = useGetPartnerQuery(opportunity.partnerId);
  const { data: users, isPending: loadingUsers } = useUsersByPartnerQuery(opportunity.partnerId);
  const { data: lenderProfiles, isPending: loadingLenderProfiles } = useLenderProfilesByPartnerQuery(opportunity.partnerId);

  const assignedUsers = useMemo(
    () => users?.filter((u) => (opportunity.assigneeIds ?? []).includes(u.id)) ?? [],
    [opportunity.assigneeIds, users],
  );

  const assignedLenderProfiles = useMemo(
    () => compact(assignedUsers.map((u) => lenderProfiles?.find((p) => p.userId === u.id)) ?? []),
    [assignedUsers, lenderProfiles],
  );

  const exportToLaserProPlus = useCallback(() => {
    const laserProData = transformToLaserProPremiumFormat({
      opportunity,
      assignedUsers,
      assignedLenderProfiles,
      laserProOfficerCodePrefix: opportunityPartner?.laserProOfficerCodePrefix,
    });

    const blob = new Blob([laserProData], {
      type: 'application/zip',
    });

    saveAs(blob, `${loanDisplayName ?? application.id}_lp.txt`);
  }, [application.id, assignedLenderProfiles, assignedUsers, loanDisplayName, opportunity, opportunityPartner?.laserProOfficerCodePrefix]);

  if (loadingUsers || loadingLenderProfiles || loadingPartner) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} width={'100%'} p={4} border={`1px solid ${greyPalette[300]}`}>
      <ExportLine name={'Export to LaserPro Plus'} onExportClick={exportToLaserProPlus} />
    </Flex>
  );
};
