import React, { useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../ApplicationContext';
import { getRelevantSections } from '../shared/sectionUtils';
import { CreditMemoSideNav } from './Sections/components/CreditMemoSideNav';
import { CreditMemoContent } from './Sections/components/CreditMemoContent';

export const CreditMemoPage: React.FC = () => {
  const { product, opportunity } = useContext(ApplicationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSectionId = searchParams.get('section');

  const creditMemoSections = useMemo(
    () => getRelevantSections(product.creditMemoConfiguration?.sections ?? [], opportunity),
    [opportunity, product.creditMemoConfiguration?.sections],
  );

  const currentSection = useMemo(() => creditMemoSections.find((s) => s.id === currentSectionId), [creditMemoSections, currentSectionId]);

  useEffect(() => {
    if (!currentSection) {
      const firstSectionId = product?.creditMemoConfiguration?.sections[0]?.id;

      if (firstSectionId) {
        setSearchParams({ section: firstSectionId }, { replace: true });
      }
    }
  }, [currentSection, product, setSearchParams]);

  return (
    <Flex flex={1} border={'1px solid #E0E0E0'} borderRadius={1}>
      <CreditMemoSideNav sections={creditMemoSections} />
      <CreditMemoContent section={currentSection} />
    </Flex>
  );
};
